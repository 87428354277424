import defaultUser from "../utils/default-user";
import { authApi } from "./api";
const TOKEN_KEY = "@es-tms/authToken";
const REFRESH_TOKEN_KEY = "@es-tms/refreshtokencode";

export async function signIn(email, password) {
  const authInfo = await authApi
    .post("auth/login", {
      DesEmailUsuario: email,
      ValSenhaUsuario: password,
    })
    .then((response) => {
      return {
        isOk: true,
        data: response.data,
      };
    })
    .catch((error) => {
      logout();
      return {
        isOk: false,
        message: "Erro ao efetuar login",
      };
    });
  return {
    isOk: authInfo.isOk,
    data: authInfo.data,
    message: authInfo.message,
  };
}

export const isAuthenticated = () => {
  return localStorage.getItem(TOKEN_KEY) !== null;
};

export async function getUser() {
  const loggedUser = await authApi
    .get("/auth/loggedUser", {
      params: { token: getToken() },
    })
    .then((response) => {
      return {
        isOk: true,
        data: response.data,
      };
    })
    .catch((error) => {
      logout();
      return {
        isOk: false,
        message: "Erro ao carregar usuario logado",
      };
    });
  return {
    isOk: loggedUser.isOk,
    data: loggedUser.data,
    message: loggedUser.message,
  };
}

export async function createAccount(data) {
  const authInfo = await authApi
    .post("users", data)
    .then((response) => {
      return {
        isOk: true,
      };
    })
    .catch((error) => {
      logout();
      return {
        isOk: false,
        message: "Error on account creating",
      };
    });

  return authInfo;
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to change password",
    };
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to reset password",
    };
  }
}

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getRefreshTokenCode = () =>
  localStorage.getItem(REFRESH_TOKEN_KEY);
export const login = (data) => {
  localStorage.setItem(TOKEN_KEY, data.token);
  localStorage.setItem(REFRESH_TOKEN_KEY, data.refreshTokenCode);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};
