import React, { useState, useRef, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import appInfo from '../../app-info'

import Form, { SimpleItem, Label, ButtonItem, ButtonOptions, RequiredRule, EmailRule, GroupItem } from 'devextreme-react/form'
import LoadIndicator from 'devextreme-react/load-indicator'
import notify from 'devextreme/ui/notify'
import { useAuth } from '../../contexts/auth'

import './login-form.scss'
import { Typography } from '@material-ui/core'
import { useDataLoader } from '../../contexts/dataLoaderContext'

export default function () {
  const history = useHistory()
  const { signIn } = useAuth()
  const [loading, setLoading] = useState(false)
  const formData = useRef({})
  const { dlFilters } = useDataLoader()

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      const { email, password } = formData.current
      setLoading(true)

      const result = await signIn(email, password)
      if (!result.isOk) {
        setLoading(false)
        notify(result.message, 'error', 2000)
      }
    },
    [signIn]
  )

  const emailEditorOptions = {
    stylingMode: 'filled',
    placeholder: 'Email',
    mode: 'email',
  }
  const passwordEditorOptions = {
    stylingMode: 'filled',
    placeholder: 'Senha',
    mode: 'password',
    elementAttr: { class: 'password-margin' },
  }
  const rememberMeEditorOptions = {
    text: 'Lembrar de mim',
    elementAttr: { class: 'form-text' },
  }

  return (
    <React.Fragment>
      <img className="logo-login" src={require('../../images/Nexxus.ico')} alt="" width="80" height="81" />
      <div className={'login-header'}>
        <div className={'title'}>{appInfo.title}</div>
        <div>Faça login em sua conta</div>
      </div>
      <form className={'login-form'} onSubmit={onSubmit}>
        <Form formData={formData.current} disabled={loading}>
          <SimpleItem dataField={'email'} editorOptions={emailEditorOptions}>
            <RequiredRule message="Preencha o email" />
            <EmailRule message="Email inválido" />
            <Label visible={false} />
          </SimpleItem>
          <SimpleItem
            dataField={'password'}
            editorOptions={passwordEditorOptions}
            // editorOptions={passwordEditorOptions}
          >
            <RequiredRule message="Senha obrigatória" />
            <Label visible={false} />
          </SimpleItem>
          {/* <Item
                  dataField={"rememberMe"}
                  editorType={"dxCheckBox"}
                  editorOptions={rememberMeEditorOptions}
                >
                  <Label visible={false} />
                </Item> */}
          <ButtonItem>
            <ButtonOptions width={'100%'} height={40} type={'default'} useSubmitBehavior={true}>
              <span className="dx-button-text">{loading ? <LoadIndicator width={'24px'} height={'24px'} visible={true} /> : 'Entrar'}</span>
            </ButtonOptions>
          </ButtonItem>
          <SimpleItem>
            <Typography style={{ marginTop: '1%', marginBottom: '-12%' }} variant="body1" align="center" color="textSecondary">
              v0.8.3.0
            </Typography>
          </SimpleItem>
        </Form>
      </form>
    </React.Fragment>
  )
}
