import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import appInfo from './app-info'
import routes from './app-routes'
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts'
import { PrivateRoute } from './components/private-route/PrivateRoute'
import { Footer } from './components'
import { useAuth } from './contexts/auth'

export default function () {
  const { user, nav } = useAuth()
  return (
    <SideNavBarLayout title={appInfo.title}>
      <Switch>
        {routes.map(({ path, component, roles }) => (
          <PrivateRoute exact key={path} path={path} user={user} roles={roles} homePath={nav[0]?.path || '/home'} component={component} />
        ))}
        <Redirect to={nav[0]?.path || '/home'} />
      </Switch>
      <Footer>
        Copyright © 2011-{new Date().getFullYear()} Nexxus Evolution Systems
        <br />
        Todas as marcas comerciais ou registradas são propriedade de seus respectivos proprietários.
      </Footer>
    </SideNavBarLayout>
  )
}
