import React, { useState, useRef, useEffect } from 'react'
import 'devextreme/data/odata/store'
import { Item } from 'devextreme-react/box'
import '../../components/data-loader/style.scss'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import DataLoader from '../../components/data-loader/DataLoader'
import TicketsGrid from './ticketsGrid'
import TicketsCards from './ticketsCards'
import { useDataLoader } from '../../contexts/dataLoaderContext'
import { Box, Button, SelectBox } from 'devextreme-react'
import { Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { useAuth } from '../../contexts/auth'
import NewTicket from './newTicket'
import NewTicketClient from './newTicketClient'
import role from '../../utils/role'

export default function Ticket(props) {
  const theme = useTheme()
  const { loading, setNewTicketFormVisible, newTicketFormVisible, dlFilters, SetShouldLoadOnRender } = useDataLoader()
  const [layout, setLayout] = useState('Card')
  const { user } = useAuth()

  const checkUserType = () => {
    const roles = [role.Cliente, role.ClienteGerente]
    let remove = true
    user.roles.forEach((x) => {
      if (remove) {
        if (roles && roles.indexOf(x.NomPermissao) === -1) {
          remove = true
        } else {
          remove = false
        }
      }
    })
    if (remove) return <NewTicket />

    return <NewTicketClient />
  }
  useEffect(() => {}, [])
  let baseFilters = {}

  baseFilters.clientId = user.CodCliente
  baseFilters.clientGroupId = user.CodClienteGrupo

  return (
    <React.Fragment>
      <Button
        style={{ float: 'left' }}
        type={'back'}
        icon={'chevronleft'}
        className="content-block"
        onClick={() => {
          props.history.goBack()
        }}
      />
      <Typography className="content-block" variant={'h2'}>
        Ticket
      </Typography>
      <div className="form-container">
        <Box style={{ margin: '0 0 2% 1%' }} width="100%" height="auto">
          <Item ratio={0} baseSize={'8%'}>
            <Button
              type="default"
              style={{ marginTop: 20 }}
              text={'Novo'}
              onClick={(e) => {
                setNewTicketFormVisible('initial')
              }}
              icon="add"
            />
          </Item>
          <Item ratio={0} baseSize={'18%'}>
            <DataLoader
              url={'ticket/completeTickets'}
              allowedFilters={{
                date: {},
                ticket: {},
                //client: {},
                tecnician: {},
                situation: {},
                priority: {},
              }}
              baseFilters={baseFilters}
            />
          </Item>

          <Item ratio={0} baseSize={'59%'}></Item>
          <Item ratio={0} baseSize={'20%'}>
            <SelectBox
              placeholder="Teste"
              width="fit-content"
              defaultValue="Card"
              label="Layout"
              labelMode={'floating'}
              stylingMode={'outlined'}
              dataSource={['Card', 'Grid']}
              //acceptCustomValue={true}
              onValueChanged={(event) => {
                setLayout(event.value)
              }}
            />
          </Item>
        </Box>
      </div>

      <div style={{ marginTop: newTicketFormVisible === 'none' ? '-6%' : '-2%' }}>{checkUserType()}</div>

      <div id="grid">{layout === 'Grid' ? <TicketsGrid initialDate={dlFilters.initialDate} finalDate={dlFilters.finalDate} /> : <TicketsCards />}</div>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  )
}
