import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import {
  getUser,
  signIn as sendSignInRequest,
  logout,
  login,
} from "../api/auth";
import { authApi, api } from "../api/api";
import notify from "devextreme/ui/notify";
import usePersistedState from "../hooks/usePersistedState";

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [valid, SetValid] = useState(true);
  const [nav, setNav] = useState([]);

  useEffect(() => {
    (async function () {
      const result = await getUser();
      console.log("user logado", result);
      if (result.isOk) {
        setUser(result.data.user);
      }

      setLoading(false);
    })();
  }, []);

  const refreshToken = useCallback(async (token, refreshTokenCode) => {
    await authApi
      .post("auth/refreshtoken", {
        DesLastToken: token,
        DesRefreshTokenCode: refreshTokenCode,
      })
      .then((response) => {
        login(response.data);
      })
      .catch((error) => {
        if (error.response) {
          logout();
          notify(
            `Sessão expirada, faça login novamente! \n ${error.response.data.message}`,
            "error",
            2400
          );
        } else if (error.request) {
          logout();
          notify(
            `Sessão expirada, faça login novamente! \n ${error.request}`,
            "error",
            2400
          );
        } else {
        }
      });
  }, []);

  const signIn = useCallback(async (email, password) => {
    const result = await sendSignInRequest(email, password);

    if (result.isOk) {
      login(result.data);

      setUser(result.data.user);
    }

    return result;
  }, []);

  async function validateToken(token) {
    await authApi
      .get("auth/validatetoken", {
        params: {
          token: token,
        },
      })
      .then((response) => {
        SetValid(response.data.isValid);
      });
  }

  const signOut = useCallback(() => {
    logout();
    setUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        signOut,
        loading,
        valid,
        validateToken,
        refreshToken,
        nav,
        setNav,
      }}
      {...props}
    />
  );
}

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
