import React from "react";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({
  component: Component,
  roles,
  user,
  homePath,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (!user) {
        // not logged in so redirect to login page with the return url
        return <Redirect to={homePath} />;
      }
      // check if route is restricted by role
      let remove = true;
      user.roles.forEach((x) => {
        if (remove) {
          if (roles && roles.indexOf(x.NomPermissao) === -1) {
            remove = true;
          } else {
            remove = false;
          }
        }
      });
      if (remove) return <Redirect to={{ pathname: homePath }} />;

      // authorised so return component
      return <Component {...props} />;
    }}
  />
);
