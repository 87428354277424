import { Typography } from '@material-ui/core'
import { Button, DataGrid, TextBox } from 'devextreme-react'
import { Column, Editing, FilterRow, Lookup, Pager } from 'devextreme-react/data-grid'
import { formatDate, formatMessage } from 'devextreme/localization'
import notify from 'devextreme/ui/notify'
import React, { useEffect, useState } from 'react'
import { api } from '../../api/api'
import DataLoader from '../../components/data-loader/DataLoader'
import { useAuth } from '../../contexts/auth'
import { useDataLoader } from '../../contexts/dataLoaderContext'

export default function Profile(props) {
  const [contacts, setContacts] = useState()
  const [load, setLoad] = useState(false)
  const [clientContact, setClientContact] = useState([])
  const { dlFilters, SetShouldLoadOnRender } = useDataLoader()
  const { user } = useAuth()

  useEffect(() => {
    async function loadData() {
      await api
        .get('/contact/get', { params: { contactId: user.CodClienteContato } })
        .then((response) => {
          setContacts(response.data)
        })
        .catch((error) => {})
    }
    loadData()
  }, [load, user])

  async function updateRow(data) {
    return await api
      .put('/contact', data)
      .then((response) => {
        notify(formatMessage('UpdatedSuccess'), 'success', 3000)
        setLoad(true)
        return false
      })
      .catch((error) => {
        notify(formatMessage('UpdateError'), 'error', 3000)
        setLoad(true)

        return true
      })
  }

  return (
    <React.Fragment>
      <Button
        style={{ float: 'left' }}
        type={'back'}
        icon={'chevronleft'}
        className="content-block"
        onClick={() => {
          SetShouldLoadOnRender(true)
          props.history.goBack()
        }}
      />

      <Typography className="content-block" variant={'h2'}>
        Perfil
      </Typography>
      <DataLoader
        visible={false}
        allowedFilters={{
          client: {},
          clientGroup: {},
        }}
      />
      <br />
      <br />
      <DataGrid
        className={'dx-card'}
        dataSource={contacts}
        showBorders={false}
        focusedRowEnabled={true}
        rowAlternationEnabled={true}
        keyExpr={'CodClienteContato'}
        onRowUpdating={async (e) => {
          const newObj = { ...e.oldData, ...e.newData }
          e.cancel = updateRow(newObj)
        }}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
      >
        <Pager showPageSizeSelector={true} showInfo={true} />

        <Editing mode="popup" allowUpdating={true} />

        <Column dataField={'DesEmailContato'} editorOptions={{ showClearButton: true }} dataType="string" caption={'Email'} />

        <Column dataField={'NomClienteContato'} dataType="string" caption={'Nome Contato'} />
        <Column dataField={'DesTelefoneContato'} dataType="string" caption={'Telefone'} />
        <Column
          dataField={'CodCliente'}
          editorOptions={{
            readOnly: true,
          }}
          dataType="number"
          caption={'Cliente'}
        >
          <Lookup dataSource={dlFilters.clients} valueExpr="Id" displayExpr="Value" />
        </Column>
        <Column dataField={'CodClienteGrupo'} dataType="number" caption={'Grupo de Cliente'} editorOptions={{ readOnly: true }}>
          <Lookup dataSource={dlFilters.clientGroups} valueExpr="Id" displayExpr="Value" />
        </Column>
        <Column customizeText={() => '*********'} dataField={'ValSenhaContato'} editorOptions={{ mode: 'password' }} caption={'Senha'} />
      </DataGrid>
    </React.Fragment>
  )
}
