import { Card, makeStyles, CardActionArea, CardContent, Typography, useTheme, Avatar, Divider, CardMedia } from '@material-ui/core'
import { Box, Chart, PieChart } from 'devextreme-react'
import { Item } from 'devextreme-react/box'
import React, { useEffect, useState } from 'react'
import './home.scss'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import { api } from '../../api/api'
import { formatMessage } from 'devextreme/localization'
import { ArgumentAxis, CommonAxisSettings, Connector, Label, Legend, Series, Size, Title, ValueAxis } from 'devextreme-react/chart'
import { useHistory } from 'react-router-dom'
import { Font } from 'devextreme-react/pie-chart'
import { useAuth } from '../../contexts/auth'

export default function HomeClient() {
  const history = useHistory()
  const { user } = useAuth()

  const theme = useTheme()
  const [data, setData] = useState([])
  const useStyles = makeStyles({
    root: {
      margin: '1% 1% 1% 1%',
      minWidth: '300px',
      //maxWidth: "500px",
      flex: '1 1 0px',
    },
  })
  const classes = useStyles()
  const quickAccess = [
    {
      name: 'Tickets',
      desc: 'Veja seus Tickets',
      link: '/tickets',
    },
    {
      name: 'Apontamentos',
      desc: 'Veja como suas Horas estão sendo utilizadas',
      link: '/appointments',
    },
    {
      name: 'Perfil',
      desc: 'Altere suas informações',
      link: '/profile',
    },
  ]

  useEffect(() => {
    async function loadData() {
      await api
        .get('home/dashboarddata', {
          params: {
            clientId: user.CodCliente,
            clientGroupId: user.CodClienteGrupo,
          },
        })
        .then((response) => {
          setData(response.data)
        })
    }
    loadData()
  }, [])

  const calcDiff = (newValue, oldValue) => {
    const res = Math.round(Math.abs(((newValue - oldValue) / newValue) * 100))
    if (isNaN(res)) return 0
    if (!isFinite(res)) return 0
    return res
  }

  return (
    <React.Fragment>
      <h2 className={'content-block'}>{formatMessage('Home')}</h2>
      <div className="form-container">
        <Typography className="content-block" style={{ margin: '1% 1% 1% 1%' }} variant={'h6'} component="h5">
          Acesso Rápido
        </Typography>
        <Divider style={{ margin: '-0.5% 1% 1% 1%' }} />

        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
          {quickAccess.map((item) => (
            <Card key={item.name} className={classes.root}>
              <CardActionArea
                onClick={() => {
                  history.push(item.link)
                }}
              >
                <CardContent>
                  <Typography gutterBottom variant="h6" align="center" component="h2">
                    {item.name}
                  </Typography>
                  <Typography align="center" variant="body2" color="textSecondary" component="p">
                    {item.desc}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </div>
        <Typography className="content-block" style={{ margin: '1% 1% 1% 1%' }} variant={'h6'} component="h5">
          Indicadores
        </Typography>
        <Divider style={{ margin: '-0.5% 1% 1% 1%' }} />

        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
          {data[0]?.map((item) => (
            <Card key={item.name} className={classes.root}>
              <CardContent>
                <Typography align="center" gutterBottom variant={'h6'} component="h5">
                  {formatMessage(item.name)}
                </Typography>
                {item.value > item.diff ? (
                  <KeyboardArrowUpIcon
                    style={{
                      margin: '0% 0% 0% 40%',
                      width: '10%',
                      height: '10%',
                      float: 'left',
                      color: item.value > item.diff ? theme.palette.error.dark : theme.palette.success.dark,
                      //color: item.color,
                    }}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    style={{
                      margin: '0% 0% 0% 40%',
                      width: '10%',
                      height: '10%',
                      float: 'left',
                      color: item.value > item.diff ? theme.palette.error.dark : theme.palette.success.dark,
                      //color: item.color,
                    }}
                  />
                )}
                <Typography
                  //align="center"
                  variant="h5"
                  component="h3"
                  color="initial"
                >
                  {item.value}
                </Typography>
                <Typography align="center" variant="h6" component="h6" color="textSecondary">
                  ({item.diff}) {calcDiff(item.diff, item.value)}% {item.value > item.diff ? formatMessage(item.diffTextPos) : formatMessage(item.diffTextNeg)}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
          <Card className={classes.root}>
            <CardContent>
              <PieChart type="donut" palette="material" dataSource={data[2] ? data[2] : []}>
                <Title text={'Status de Tickets nos últimos 7 dias'} font={{ color: theme.palette.text.primary }} />
                <Series valueField="Count" argumentField="Situation" name="Tickets">
                  <Label visible={true}>
                    <Connector visible={true} width={1} />
                  </Label>
                </Series>

                <Legend verticalAlignment="bottom" font={{ color: theme.palette.text.primary }} horizontalAlignment="center"></Legend>
              </PieChart>
            </CardContent>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}
