import React, { useMemo } from 'react'
import ContextMenu, { Position } from 'devextreme-react/context-menu'
import List from 'devextreme-react/list'
import { useAuth } from '../../contexts/auth'
import './user-panel.scss'
import { useLocalization } from '../../contexts/localizationContext'
import zodiac from '../../icons/zodiaco.svg'
import { useHistory } from 'react-router-dom'
export default function ({ menuMode }) {
  const { changeLanguage } = useLocalization()
  const { user, signOut } = useAuth()

  const languages = [
    { language: 'Português', abreviation: 'pt' },
    { language: 'Inglês', abreviation: 'en' },
  ]
  const history = useHistory()

  const menuItems = useMemo(
    () => [
      // {
      //   text: "Profile",
      //   icon: "user",
      // },
      // {
      //   text: "Linguagem",
      //   icon: "globe",
      //   items: languages.map((lang) => {
      //     return {
      //       text: lang.language,
      //       onClick: () => {
      //         changeLanguage(lang.abreviation);
      //       },
      //     };
      //   }),
      // },
      // {
      //   text: "Horoscopo",
      //   icon: zodiac,
      //   onClick: () => {
      //     history.push("/horoscopo");
      //   },
      // },
      {
        text: 'Sair',
        icon: 'runner',
        onClick: signOut,
      },
    ],
    [changeLanguage, languages, signOut]
  )

  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        <div className={'image-container'}>
          <div
            style={{
              background: `#fff`,
              backgroundSize: 'cover',
            }}
            className={'user-image'}
          />
        </div>
        <div className={'user-name'}>
          {user.email !== null ? (user.email.split(' ')[0].length >= 23 ? user.email.split(' ')[0].substring(0, 21).concat('.') : user.email.split(' ')[0]) : ''}
        </div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu items={menuItems} target={'.user-button'} showEvent={'dxclick'} width={210} cssClass={'user-menu'}>
          <Position my={'top center'} at={'bottom center'} />
        </ContextMenu>
      )}
      {menuMode === 'list' && <List className={'dx-toolbar-menu-action'} items={menuItems} />}
    </div>
  )
}
