import React, { useState } from 'react'
import FileManager from 'devextreme-react/file-manager';

export default function InternatTraining() {
  const [videoSelected, setVideoSelected] = useState({});


  function setDisplayImagePopup(e){
      setVideoSelected({})
      setVideoSelected({
          popupVisible: true,
          name: e.file.name,
          video: window.api_files+e.file.name,
      })
  }

  return (
    <React.Fragment>
      <div style={{ margin: '2rem' }}>
        <p style={{ color: '#78be20' }}>
            <b>Treinamentos Internos</b>
        </p>
        <div>
            {!videoSelected.popupVisible ? '' :
                <video controls style={{ margin: 'auto', display: 'block', width:'100%', maxHeight: '500px' }}>
                    <source src={videoSelected.video} type="video/mp4"/>
                </video>
            }
            <br/>
            <FileManager
                height='600'
                itemView= {{ mode: "thumbnails" }}
                rootFolderName="Treinamentos"
                fileSystemProvider={window.dataFile}
                onSelectedFileOpened={setDisplayImagePopup}
            />
        </div>
      </div>
    </React.Fragment>
  )
}
