import React, { useRef, useState } from 'react'
import { Card, CardContent, Divider, Typography } from '@material-ui/core'
import { Form, LoadIndicator } from 'devextreme-react'
import { ButtonItem, ButtonOptions, EmptyItem, GroupItem, Item, Label, RequiredRule } from 'devextreme-react/form'
import { api } from '../../api/api'
import notify from 'devextreme/ui/notify'
import { formatMessage } from 'devextreme/localization'
import { useDataLoader } from '../../contexts/dataLoaderContext'
import userEvent from '@testing-library/user-event'

export default (props) => {
  const [clientGroupId, setClientGroupId] = useState(0)
  const { dlFilters, setNewTicketFormVisible, newTicketFormVisible, SetShouldLoadOnRender } = useDataLoader()

  async function createTicket(data) {
    data.TicketResposta = [
      {
        CodTicket: data.CodTicket,
        DatTicketResposta: new Date(),
        CodClienteContato: data.CodClienteContato,
        CodUsuarioContato: data.CodUsuarioContato,
        TipPublica: data.TipPublica,
        DesTicketResposta: data.DesTicketResposta,
      },
    ]
    return await api
      .post('/ticket', data)
      .then((response) => {
        notify(formatMessage('UpdatedSuccess'), 'success', 3000)
        //setLoa(true);

        setNewTicketFormVisible('none')
        return false
      })
      .catch((error) => {
        notify(formatMessage('UpdateError'), 'error', 3000)
        //setLoad(true);

        return true
      })
  }

  const ref = useRef()

  return (
    <form
      className={'create-account-form'}
      onSubmit={(e) => {
        e.preventDefault()
        const data = ref.current.props.formData
        createTicket(data)
      }}
    >
      <div style={{ display: newTicketFormVisible }}>
        <Card>
          <CardContent>
            <Form
              formData={{ DatAbertura: new Date(), TipSituacao: 0 }}
              //disabled={props.loading}
              colCount={1}
              width={'100%'}
              height={'100%'}
              ref={ref}
            >
              <Item
                dataField={'DesAssunto'}
                editorType={'dxTextBox'}
                editorOptions={{
                  stylingMode: 'filled',
                  readOnly: false,
                  maxLength: 100,
                }}
              >
                <RequiredRule />
                <Label visible={true} text={'Assunto'} />
              </Item>
              <GroupItem colCount={4}>
                <Item
                  dataField={'CodUsuario'}
                  editorType={'dxSelectBox'}
                  editorOptions={{
                    stylingMode: 'filled',
                    valueExpr: 'Id',
                    displayExpr: 'Value',
                    dataSource: dlFilters.tecnicians,
                    readOnly: false,
                  }}
                >
                  <RequiredRule message="Preencha o responsável" />
                  <Label visible={true} text={'Responsável'} />
                </Item>

                <Item
                  dataField={'CodSuporteMotivo'}
                  editorType={'dxSelectBox'}
                  editorOptions={{
                    stylingMode: 'filled',
                    valueExpr: 'Id',
                    displayExpr: 'Value',
                    dataSource: dlFilters.reasons,
                    readOnly: false,
                  }}
                >
                  <RequiredRule />
                  <Label visible={true} text={'Motivo'} />
                </Item>
                <Item
                  dataField={'CodCliente'}
                  editorType={'dxSelectBox'}
                  editorOptions={{
                    stylingMode: 'filled',
                    valueExpr: 'Id',
                    displayExpr: 'Value',
                    dataSource: dlFilters.clients,
                    readOnly: false,
                  }}
                >
                  <RequiredRule />
                  <Label visible={true} text={'Cliente'} />
                </Item>
                <Item
                  dataField={'CodSuporteEquipe'}
                  editorType={'dxSelectBox'}
                  editorOptions={{
                    stylingMode: 'filled',
                    valueExpr: 'Id',
                    displayExpr: 'Value',
                    dataSource: dlFilters.teams,
                    readOnly: false,
                  }}
                >
                  <RequiredRule />
                  <Label visible={true} text={'Equipe'} />
                </Item>
                <Item
                  dataField={'TipPrioridade'}
                  editorType={'dxSelectBox'}
                  editorOptions={{
                    stylingMode: 'filled',
                    placeholder: 'Prioridade',
                    valueExpr: 'Id',
                    displayExpr: 'Value',
                    dataSource: [
                      { Id: 0, Value: 'Baixa' },
                      { Id: 1, Value: 'Média' },
                      { Id: 2, Value: 'Alta' },
                      { Id: 3, Value: 'Urgente' },
                    ],
                    readOnly: false,
                  }}
                >
                  <RequiredRule />
                  <Label visible={true} text={'Prioridade'} />
                </Item>
                <Item
                  dataField={'TipSituacao'}
                  editorType={'dxSelectBox'}
                  editorOptions={{
                    stylingMode: 'filled',
                    placeholder: 'Situação',
                    valueExpr: 'Id',
                    displayExpr: 'Value',
                    dataSource: [
                      { Id: 0, Value: 'Aberto' },
                      { Id: 1, Value: 'Pendente' },
                      { Id: 2, Value: 'Resolvido' },
                      { Id: 3, Value: 'Fechado' },
                    ],
                    readOnly: true,
                  }}
                >
                  <Label visible={true} text={'Situação'} />
                </Item>
                <Item
                  dataField={'DatAbertura'}
                  editorType={'dxDateBox'}
                  editorOptions={{
                    stylingMode: 'filled',
                    readOnly: true,
                    type: 'datetime',
                  }}
                >
                  <Label visible={true} text={'Aberto em'} />
                </Item>
                <Item
                  dataField={'DesTag'}
                  editorType={'dxTextBox'}
                  editorOptions={{
                    stylingMode: 'filled',
                    readOnly: false,
                  }}
                >
                  <Label visible={true} text={'Tags(Separe as palavras por vírgulas)'} />
                </Item>
              </GroupItem>
              <GroupItem caption="Mensagem">
                <Item
                  dataField={'CodTicket'}
                  editorType={'dxTextBox'}
                  editorOptions={{
                    stylingMode: 'filled',
                    readOnly: true,
                    visible: false,
                  }}
                >
                  <Label visible={false} text={'Contato'} />
                </Item>
                <GroupItem colCount={3}>
                  <Item
                    dataField={'CodUsuarioContato'}
                    editorType={'dxSelectBox'}
                    editorOptions={{
                      stylingMode: 'filled',
                      valueExpr: 'Id',
                      displayExpr: 'Value',
                      showClearButton: true,
                      dataSource: dlFilters.tecnicians,
                      readOnly: false,
                    }}
                  >
                    <Label visible={true} text={'Técnico'} />
                  </Item>
                  <Item
                    dataField={'CodClienteContato'}
                    editorType={'dxSelectBox'}
                    editorOptions={{
                      stylingMode: 'filled',
                      valueExpr: 'Id',
                      displayExpr: 'Value',
                      showClearButton: true,
                      dataSource: dlFilters.contacts,
                      readOnly: false,
                    }}
                  >
                    <Label visible={true} text={'Contato'} />
                  </Item>
                  <Item
                    dataField={'TipPublica'}
                    editorType={'dxSelectBox'}
                    editorOptions={{
                      stylingMode: 'filled',
                      valueExpr: 'Id',
                      displayExpr: 'Value',
                      dataSource: [
                        { Id: 1, Value: 'Pública' },
                        { Id: false, Value: 'Privada' },
                      ],
                      readOnly: false,
                    }}
                  >
                    <Label visible={true} text={'Tipo da Mensagem'} />
                    <RequiredRule />
                  </Item>
                </GroupItem>
                <Item
                  dataField={'DesTicketResposta'}
                  editorType={'dxTextArea'}
                  style={{ height: 700 }}
                  editorOptions={{
                    stylingMode: 'filled',
                    style: { height: 700 },
                    readOnly: false,
                  }}
                >
                  <RequiredRule />
                  <Label visible={true} text={'Mensagem'} />
                </Item>
              </GroupItem>
              <EmptyItem />
              <GroupItem colCount={8}>
                <EmptyItem />
                <EmptyItem />
                <EmptyItem />
                <EmptyItem />
                <EmptyItem />
                <EmptyItem />
                <ButtonItem>
                  <ButtonOptions
                    width={'100%'}
                    type={'default'}
                    onClick={() => {
                      setNewTicketFormVisible('none')
                    }}
                    useSubmitBehavior={false}
                    text={'Cancelar'}
                  ></ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions width={'100%'} type={'default'} useSubmitBehavior={true}>
                    <span className="dx-button-text">Salvar</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </CardContent>
        </Card>
      </div>
    </form>
  )
}
