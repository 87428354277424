import React, { useEffect, useState } from 'react'
import 'devextreme/data/odata/store'
import { Item } from 'devextreme-react/box'
import '../../components/data-loader/style.scss'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import DataLoader from '../../components/data-loader/DataLoader'

import { useDataLoader } from '../../contexts/dataLoaderContext'
import { Box, SelectBox } from 'devextreme-react'
import { api } from '../../api/api'
import moment from 'moment'
import { Card, makeStyles, CardContent, Typography, useTheme, Divider, Collapse, IconButton, CardActionArea } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { Button } from 'devextreme-react'
import Form, { GroupItem, Label } from 'devextreme-react/form'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../contexts/auth'

export default function Appointments(props) {
  const theme = useTheme()
  const { loading, data, setData, dlFilters, SetShouldLoadOnRender } = useDataLoader()

  const [expanded, setExpanded] = React.useState(false)
  const history = useHistory()
  const [currentAppointment, setCurrentAppointment] = useState('')
  const [margin, setMargin] = useState('2% 3% 2% 2.7%')
  const { user } = useAuth()

  const useStyles = makeStyles({
    root: {
      maxWidth: '20%',
      minWidth: 345,
      minHeight: 60,
      margin: '0px 2% 2% 0',
    },
    media: {
      height: 140,
    },
    controls: {
      bott: 100,
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    chip: {
      // display: "flex",
      // justifyContent: "center",
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  })

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  const classes = useStyles()

  const getDate = (date) => {
    const newDate = `${moment(date).format('MMMM')}/${moment(date).format('YYYY')}`

    return newDate
  }

  let baseFilters = {}

  baseFilters.clientId = user.CodCliente
  baseFilters.clientGroupId = user.CodClienteGrupo

  return (
    <React.Fragment>
      <Button
        style={{ float: 'left' }}
        type={'back'}
        icon={'chevronleft'}
        className="content-block"
        onClick={() => {
          SetShouldLoadOnRender(true)
          props.history.goBack()
        }}
      />
      <Typography className="content-block" variant={'h2'}>
        Resumo de Apontamentos
      </Typography>
      <div className="form-container">
        <Box style={{ margin: '0 0 0 2%' }} width="89%" height="auto">
          <Item ratio={0} baseSize={'90%'}>
            <DataLoader
              url={'ticket/appointments'}
              visible={true}
              allowedFilters={{
                year: {},
                //client: {},
                //clientGroup: {},
              }}
              baseFilters={baseFilters}
            />
          </Item>
        </Box>
      </div>
      <div id="grid">
        {Array.isArray(data) &&
          data.length !== undefined &&
          data.map((client) => {
            return (
              <>
                {' '}
                <IconButton
                  onMouseOver={(e) => {}}
                  key={client.CodCliente}
                  style={{ margin: '0% 20% -7% 83.5%' }}
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: client.CodCliente === currentAppointment && expanded,
                  })}
                  onClick={() => {
                    setCurrentAppointment(client.CodCliente)
                    handleExpandClick()
                  }}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
                <Typography
                  onClick={() => {
                    setMargin('2% 15% 2% 15.7%')
                    setCurrentAppointment(client.CodCliente)
                    handleExpandClick()
                    setMargin('2% 3% 2% 2.7%')
                  }}
                  className="content-block"
                  style={{ margin: margin }}
                  variant={'h6'}
                >
                  {client.NomCliente}
                </Typography>
                <Divider style={{ margin: '-1.5% 14% 2% 2.5%' }} />
                <Collapse in={client.CodCliente === currentAppointment && expanded} timeout="auto" unmountOnExit>
                  <div
                    className="form-container"
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      margin: '0% 2% 1% 2.5%',
                    }}
                  >
                    {client.HorasTotal?.map((item) => (
                      <Card key={item.Key} className={classes.root}>
                        <CardActionArea
                          onClick={(e) => {
                            SetShouldLoadOnRender(true)
                            //dlFilters.setClientId(client.CodCliente);

                            Cache['appointment'] = item
                            history.push('/appointments_by_client')
                          }}
                        >
                          <CardContent>
                            <Box direction="col" width={'auto'} height={'auto'}>
                              <Item ratio={0} baseSize={'100%'}>
                                <CardContent>
                                  <Typography
                                    align={'center'}
                                    variant="h5"
                                    color="textPrimary"
                                    component="h5"
                                    style={{
                                      marginBottom: '1.5%',
                                    }}
                                  >
                                    {getDate(item.Key)}
                                  </Typography>
                                </CardContent>
                              </Item>
                              <Item ratio={0} baseSize={'100%'}>
                                <Box direction="row" width={'auto'} height={'auto'}>
                                  <Item ratio={0} baseSize={'50%'}>
                                    <CardContent>
                                      <Typography
                                        align={'center'}
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                        style={{
                                          marginBottom: '1.5%',
                                        }}
                                      >
                                        Horas Contratadas
                                      </Typography>
                                      <Typography
                                        align={'center'}
                                        variant="h4"
                                        color="textPrimary"
                                        component="h4"
                                        style={{
                                          marginBottom: '1.5%',
                                        }}
                                      >
                                        {`${client.HorasContratadas}:00`}
                                      </Typography>
                                    </CardContent>
                                  </Item>
                                  <Item ratio={0} baseSize={'50%'}>
                                    <CardContent>
                                      <Typography
                                        align={'center'}
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                        style={{
                                          marginBottom: '1.5%',
                                        }}
                                      >
                                        Horas Utilizadas
                                      </Typography>
                                      <Typography
                                        align={'center'}
                                        variant="h4"
                                        color="textPrimary"
                                        component="h4"
                                        style={{
                                          marginBottom: '1.5%',
                                          color: item.HorasSuporte > `${client.HorasContratadas}:00` ? theme.palette.error.dark : theme.palette.success.dark,
                                        }}
                                      >
                                        {item.HorasSuporte}
                                      </Typography>
                                    </CardContent>
                                  </Item>
                                </Box>
                              </Item>
                            </Box>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    ))}
                  </div>
                </Collapse>
              </>
            )
          })}
      </div>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  )
}
