import {formatMessage} from "devextreme/localization";

export function pageName(address) {
    let namePage;

    switch (address) {
        case '/home':
            namePage = formatMessage("Home");
            break;
        case '/home_intern':
            namePage = formatMessage("Home");
            break;
        case '/home_client_manager':
            namePage = formatMessage("Home");
            break;
        case '/home_client':
            namePage = formatMessage("Home");
            break;
        case '/profile':
            namePage = formatMessage("Perfil");
            break;
        case '/profile':
            namePage = formatMessage("Perfil");
            break;
        case '/tickets':
            namePage = formatMessage("Ticket");
            break;
        case '/appointments':
            namePage = formatMessage("Appointments");
            break;
        case '/appointments_detailed':
            namePage = formatMessage("DetailedAppointments");
            break;
        case '/contacts':
            namePage = formatMessage("Contact");
            break;
        case '/training':
            namePage = formatMessage("Training");
            break;
        default:
            namePage = formatMessage("Home")
    }

    return namePage;
}