import React, { useEffect, useRef, useState } from 'react'
import 'devextreme/data/odata/store'
import { Item } from 'devextreme-react/box'
import '../../components/data-loader/style.scss'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import DataLoader from '../../components/data-loader/DataLoader'
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Selection,
  ColumnChooser,
  ColumnFixing,
  Export,
  Grouping,
  GroupPanel,
  Summary,
  GroupItem,
  SortByGroupSummaryInfo,
  Item as ItemGrid,
  Toolbar,
} from 'devextreme-react/data-grid'

import { useDataLoader } from '../../contexts/dataLoaderContext'
import { Box } from 'devextreme-react'
import { api } from '../../api/api'
import { Chip, Typography } from '@material-ui/core'

import { Button } from 'devextreme-react'
import { useHistory } from 'react-router-dom'
import { formatMessage } from 'devextreme/localization'
import moment from 'moment'
import { red } from '@material-ui/core/colors'
import { onToolbarPreparingExportDataGridToPDF, renderExportDataGridToPDFButton } from '../../utils/exportGridToPDF'
import {
  onToolbarPreparingExportDataGridToExcel,
  renderExportMasterGridToExcelButton,
} from '../../utils/exportMasterGridToExcel'
import { Template } from 'devextreme-react/core/template'

export default function AppointmentsDetailed(props) {
  const { loading, data, dlFilters, SetShouldLoadOnRender } = useDataLoader()
  const [appointments, setAppointments] = useState([])
  const history = useHistory()
  const _data = useRef()

  const calculateCustomSummary = (options) => {
    if (options.name === 'timeSum') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0
      } else if (options.summaryProcess === 'calculate') {
        if (options.component.isRowExpanded([options.value.NomProjeto])) {
          let result = data.filter((x) => x.NomProjeto === options.value.NomProjeto).map((a) => a.Duracao)
          const actualTotal = result
            .slice(1)
            .reduce((prev, cur) => moment.duration(cur).add(prev), moment.duration(result[0]))

          var MINUTES = moment.duration(actualTotal).asMinutes()
          var m = MINUTES % 60

          var h = (MINUTES - m) / 60

          var HHMM = h.toString() + ':' + (m < 10 ? '0' : '') + m.toString()
          options.totalValue = HHMM
        }
      }
    }
  }

  return (
    <React.Fragment>
      <Button
        style={{ float: 'left' }}
        type={'back'}
        icon={'chevronleft'}
        className='content-block'
        onClick={() => {
          SetShouldLoadOnRender(true)
          props.history.goBack()
        }}
      />
      <Typography className='content-block' variant={'h2'}>
        {formatMessage('DetailedAppointments')}
      </Typography>
      <div className='form-container'>
        <Box style={{ margin: '0 0 0 2%' }} width='89%' height='auto'>
          <Item ratio={0} baseSize={'90%'}>
            <DataLoader
              url={'ticket/AppointmentsDetailed'}
              visible={true}
              allowedFilters={{
                year: {},
                month: {},
                client: {},
                count: {},
              }}
            />
          </Item>
        </Box>
      </div>
      <div id='grid'>
        <DataGrid
          style={{ margin: '2% 2.6% 2% 2.6%' }}
          className={'dx-card'}
          dataSource={data}
          rowAlternationEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          showBorders={false}
          disabled={loading}
          //focusedRowEnabled={true}
          onSelectionChanged={(e) => {}}
          keyExpr={'CodCliente'}
          defaultFocusedRowIndex={0}
          wordWrapEnabled={true}
          columnAutoWidth={true}
          columnHidingEnabled={true}
          headerFilter={{ visible: true }}
          filterPanel={{ visible: true }}
          filterRow={{ visible: true }}
          ref={_data}
        >
          <ColumnChooser enabled={true} mode='select' />
          <ColumnFixing enabled={false} />
          <Selection mode='none' />
          <Paging defaultPageSize={20} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <FilterRow visible={true} />
          <Grouping contextMenuEnabled={true} expandMode='rowClick' autoExpandAll={false} />
          <GroupPanel visible={true} /> {/* or "auto" */}
          <Column dataField={'TipContabilizar'} caption={'Contabilizar'} hidingPriority={16} />
          <Column dataField={'NomApresentacaoUsuario'} dataType='string' caption={'Responsável'} hidingPriority={15} />
          <Column
            dataField={'DatInicialTarefaApontamento'}
            dataType='datetime'
            sortOrder={'desc'}
            caption={'Inicio Tarefa'}
            hidingPriority={14}
          />
          <Column
            dataField={'DatFinalTarefaApontamento'}
            dataType='datetime'
            caption={'Fim Tarefa'}
            hidingPriority={13}
          />
          <Column dataField={'Duracao'} dataType='string' caption={'Tempo Total'} hidingPriority={12} />
          <Column
            dataField={'DatContatoSuporte'}
            dataType='datetime'
            caption={'Data contato'}
            visible={false}
            hidingPriority={14}
          />
          <Column dataField={'NomCliente'} dataType='string' caption={'Cliente'} hidingPriority={9} />
          <Column dataField={'DesTarefaApontamento'} dataType='string' caption={'Tarefa'} hidingPriority={8} />
          <Column dataField={'DesSolucaoSuporte'} dataType='string' caption={'Solução'} hidingPriority={7} />
          <Column dataField={'NomProjeto'} dataType='string' groupIndex={0} caption={'Projeto'} hidingPriority={10} />
          <Column dataField={'TipContatoSuporte'} dataType='string' caption={'Tipo Contato'} hidingPriority={3} />
          <Column dataField={'NomClienteGrupo'} dataType='string' caption={'Grupo'} hidingPriority={2} />
          <Column dataField={'HaveTicket'} dataType='string' caption={'Ticket?'} hidingPriority={1} />
          <Column
            caption={'Cor Cliente'}
            cellRender={(e) => {
              return (
                <Chip
                  variant={'default'}
                  clickable={true}
                  onClick={(e) => {}}
                  style={{
                    backgroundColor: `rgba(${e.data.CorCliente})`,
                  }}
                />
              )
            }}
            hidingPriority={4}
          />
          <Summary calculateCustomSummary={calculateCustomSummary}>
            <GroupItem
              name='timeSum'
              showInColumn='Duracao'
              summaryType='custom'
              displayFormat={'Duração total: {0}'}
              showInGroupFooter={true}
            />
          </Summary>
          <SortByGroupSummaryInfo summaryItem='count' />
          <Toolbar>
            <ItemGrid
              name='renderButtonPDF'
              render={() => renderExportDataGridToPDFButton(_data, dlFilters.initialDate, dlFilters.finalDate)}
            />
            <ItemGrid
              name='renderButtonExcel'
              render={() =>
                renderExportMasterGridToExcelButton(_data, false, dlFilters.initialDate, dlFilters.finalDate)
              }
            />
            <ItemGrid name='columnChooserButton' />
          </Toolbar>
        </DataGrid>
      </div>
      <div className='load-indicator'>
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  )
}
