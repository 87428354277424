import React, { useState, useEffect, createContext, useContext } from "react";
import { api } from "../api/api";
import moment from "moment";
//import { isAuthenticated, login, logout } from "../services/auth";

const DataLoaderContext = createContext({});
const useDataLoader = () => useContext(DataLoaderContext);
var date = new Date();

function DataLoaderProvider(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentFilters, setCurrentFilters] = useState({});
  const [finalDate, setFinalDate] = useState(moment().add(1, "d"));
  const [initialDate, setInitialDate] = useState(
    moment(new Date(date.getFullYear(), date.getMonth(), 1))
  );
  const [ticketId, setTicketId] = useState("");
  const [clientId, setClientId] = useState(null);
  const [clients, setClients] = useState([]);
  const [tecnicianId, setTecnicianId] = useState(0);
  const [tecnicians, setTecnicians] = useState([]);
  const [clientGroupId, setClientGroupId] = useState(0);
  const [clientGroups, setClientGroups] = useState([]);
  const [reasonId, setReasonId] = useState(0);
  const [reasons, setReasons] = useState([]);
  const [contactId, setContactId] = useState(0);
  const [contacts, setContacts] = useState([]);
  const [teamId, setTeamId] = useState(0);
  const [teams, setTeams] = useState([]);
  const [situations, setSituations] = useState("0,1,2,3");
  const [priorities, setPriorities] = useState("0,1,2,3");
  const [year, setYear] = useState(new Date(new Date().getFullYear(), 0, 1));
  const [month, setMonth] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [newTicketFormVisible, setNewTicketFormVisible] = useState("none");
  const [
    newTicketMessageFormVisible,
    setNewTicketMessageFormVisible,
  ] = useState("none");

  const [shouldLoadOnRender, SetShouldLoadOnRender] = useState(true);

  //const [cipGridFormula, setCipGridFormula] = useState([]);

  return (
    <DataLoaderContext.Provider
      value={{
        loading,
        setData,
        setLoading,
        data,
        shouldLoadOnRender,
        SetShouldLoadOnRender,
        newTicketFormVisible,
        setNewTicketFormVisible,
        newTicketMessageFormVisible,
        setNewTicketMessageFormVisible,
        dlFilters: {
          setFinalDate,
          setInitialDate,
          initialDate,
          finalDate,
          ticketId,
          setTicketId,
          setCurrentFilters,
          currentFilters,
          clientId,
          setClientId,
          clients,
          setClients,
          tecnicianId,
          setTecnicianId,
          tecnicians,
          setTecnicians,
          situations,
          setSituations,
          priorities,
          setPriorities,
          year,
          setYear,
          clientGroupId,
          setClientGroupId,
          clientGroups,
          setClientGroups,
          month,
          setMonth,
          reasons,
          setReasons,
          reasonId,
          setReasonId,
          contacts,
          setContacts,
          contactId,
          setContactId,
          teams,
          setTeams,
          teamId,
          setTeamId,
        },
      }}
      {...props}
    />
  );
}

export { DataLoaderProvider, useDataLoader };
