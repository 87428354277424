import { Typography } from '@material-ui/core'
import { Button, DataGrid, TextBox } from 'devextreme-react'
import { Column, Editing, Export, FilterRow, Item as ItemGrid, Lookup, Pager, Toolbar } from 'devextreme-react/data-grid'
import { formatDate, formatMessage } from 'devextreme/localization'
import notify from 'devextreme/ui/notify'
import React, { useEffect, useRef, useState } from 'react'
import { api } from '../../api/api'
import DataLoader from '../../components/data-loader/DataLoader'
import { useAuth } from '../../contexts/auth'
import { useDataLoader } from '../../contexts/dataLoaderContext'
import { onToolbarPreparingExportDataGridToPDF, renderExportDataGridToPDFButton } from '../../utils/exportGridToPDF'
import { onToolbarPreparingExportDataGridToExcel, renderExportMasterGridToExcelButton } from '../../utils/exportMasterGridToExcel'
import { Template } from 'devextreme-react/core/template'

export default function Contacts(props) {
  const [contacts, setContacts] = useState([])
  const [currentLine, setCurrentLine] = useState({})

  const [load, setLoad] = useState(false)
  const [clientContact, setClientContact] = useState([])
  const { dlFilters, SetShouldLoadOnRender } = useDataLoader()
  const { user } = useAuth()
  const _data = useRef()

  useEffect(() => {
    async function loadData() {
      await api
        .get('/contact')
        .then((response) => {
          setContacts(response.data)
        })
        .catch((error) => {})
    }

    loadData()
  }, [load])

  async function updateRow(data) {
    return await api
      .put('/contact', data)
      .then((response) => {
        notify(formatMessage('UpdatedSuccess'), 'success', 3000)
        setLoad(true)
        return false
      })
      .catch((error) => {
        notify(formatMessage('UpdateError'), 'error', 3000)
        setLoad(true)

        return true
      })
  }

  async function insertRow(data) {
    return await api
      .post('/contact', data)
      .then((response) => {
        notify(formatMessage('InsertedSuccess'), 'success', 3000)
        setLoad(true)
        return false
      })
      .catch((error) => {
        notify(formatMessage('InsertError'), 'error', 3000)
        setLoad(true)
        console.log(error)
        return true
      })
  }

  return (
    <React.Fragment>
      <Button
        style={{ float: 'left' }}
        type={'back'}
        icon={'chevronleft'}
        className="content-block"
        onClick={() => {
          SetShouldLoadOnRender(true)
          props.history.goBack()
        }}
      />

      <Typography className="content-block" variant={'h2'}>
        Contatos
      </Typography>
      <DataLoader
        visible={false}
        allowedFilters={{
          client: {},
          clientGroup: {},
        }}
      />
      <br />
      <br />
      <DataGrid
        className={'dx-card'}
        dataSource={contacts}
        showBorders={false}
        focusedRowEnabled={true}
        rowAlternationEnabled={true}
        keyExpr={'CodClienteContato'}
        onRowUpdating={(e) => {
          const newObj = { ...e.oldData, ...e.newData }

          e.cancel = updateRow(newObj)
        }}
        onRowInserting={(e) => {
          e.cancel = insertRow(e.data)
        }}
        onEditingStart={(e) => {
          setCurrentLine(e.data)
        }}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        ref={_data}
      >
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <Editing mode={'form'} allowUpdating={true} allowAdding={true} />
        <Column dataField={'NomClienteContato'} editorOptions={{ showClearButton: true }} dataType="string" caption={'Nome Contato'} />
        <Column dataField={'DesEmailContato'} editorOptions={{ showClearButton: true }} dataType="string" caption={'Email'} />
        <Column dataField={'DatRegistro'} editorOptions={{ readOnly: true }} dataType="datetime" caption={'Registrado em'} />

        <Column dataField={'DesTelefoneContato'} dataType="string" caption={'Telefone'} />
        <Column
          dataField={'CodCliente'}
          editorOptions={{
            readOnly: currentLine?.CodCliente ? false : true,
          }}
          dataType="number"
          caption={'Cliente'}
        >
          <Lookup dataSource={dlFilters.clients} valueExpr="Id" displayExpr="Value" />
        </Column>
        <Column
          editorOptions={{
            readOnly: currentLine?.CodClienteGrupo ? false : true,
          }}
          dataField={'CodClienteGrupo'}
          dataType="number"
          caption={'Grupo de Cliente'}
        >
          <Lookup dataSource={dlFilters.clientGroups} valueExpr="Id" displayExpr="Value" />
        </Column>
        <Column customizeText={() => '*********'} dataField={'ValSenhaContato'} editorOptions={{ mode: 'password' }} caption={'Senha'} />
        <Column dataField={'TipRegistroAtivo'} caption={'Ativo?'} />
        <Toolbar>
          <ItemGrid name="renderButtonPDF" render={() => renderExportDataGridToPDFButton(_data, dlFilters.initialDate, dlFilters.finalDate)} />
          <ItemGrid name="renderButtonExcel" render={() => renderExportMasterGridToExcelButton(_data, false, dlFilters.initialDate, dlFilters.finalDate)} />
          <ItemGrid name="columnChooserButton" />
        </Toolbar>
      </DataGrid>
    </React.Fragment>
  )
}
