import React, { useRef, useState } from 'react'
import { Divider, Typography } from '@material-ui/core'
import { Form, LoadIndicator } from 'devextreme-react'
import { ButtonItem, ButtonOptions, EmptyItem, GroupItem, Item, Label, RequiredRule } from 'devextreme-react/form'
import { api } from '../../api/api'
import notify from 'devextreme/ui/notify'
import { formatMessage } from 'devextreme/localization'
import { useDataLoader } from '../../contexts/dataLoaderContext'
import { useAuth } from '../../contexts/auth'

export default (props) => {
  const [clientGroupId, setClientGroupId] = useState(props.ticket?.CodClienteGrupo)
  const { dlFilters } = useDataLoader()
  const { user } = useAuth()

  async function updateTicket(data) {
    return await api
      .put('/ticket', data)
      .then((response) => {
        notify(formatMessage('UpdatedSuccess'), 'success', 3000)
        //setLoa(true);
        return false
      })
      .catch((error) => {
        notify(formatMessage('UpdateError'), 'error', 3000)
        //setLoad(true);

        return true
      })
  }

  const ref = useRef()

  return (
    <form
      className={'create-account-form'}
      onSubmit={(e) => {
        e.preventDefault()
        const data = ref.current.props.formData
        updateTicket(data)
      }}
    >
      {props.ticket && (
        <div>
          <Typography className="content-block" style={{ marginLeft: '-0.1%', marginBottom: '0.5%' }} variant={'h6'}>
            {props.ticket.CodTicket}
          </Typography>
          <Divider style={{ margin: '0% 14% 0% 0%' }} />
          <Form formData={props.ticket} disabled={props.loading} colCount={1} width={'88%'} height={'100%'} ref={ref}>
            <Item
              dataField={'DesAssunto'}
              editorType={'dxTextBox'}
              editorOptions={{
                stylingMode: 'filled',
                readOnly: false,
                maxLength: 100,
              }}
            >
              <Label visible={true} text={'Assunto'} />
              <RequiredRule />
            </Item>
            <GroupItem colCount={4}>
              <Item
                dataField={'CodUsuario'}
                editorType={'dxSelectBox'}
                editorOptions={{
                  stylingMode: 'filled',
                  valueExpr: 'Id',
                  displayExpr: 'Value',
                  dataSource: dlFilters.tecnicians,
                  readOnly: false,
                }}
              >
                <Label visible={true} text={'Responsável'} />
              </Item>

              <Item
                dataField={'CodSuporteMotivo'}
                editorType={'dxSelectBox'}
                editorOptions={{
                  stylingMode: 'filled',
                  valueExpr: 'Id',
                  displayExpr: 'Value',
                  dataSource: dlFilters.reasons,
                  readOnly: false,
                }}
              >
                <Label visible={true} text={'Motivo'} />
                <RequiredRule />
              </Item>
              <Item
                dataField={'CodCliente'}
                editorType={'dxSelectBox'}
                editorOptions={{
                  stylingMode: 'filled',
                  valueExpr: 'Id',
                  displayExpr: 'Value',
                  dataSource: dlFilters.clients,
                  readOnly: false,
                }}
              >
                <Label visible={true} text={'Cliente'} />
              </Item>
              <Item
                dataField={'TipPrioridade'}
                editorType={'dxSelectBox'}
                editorOptions={{
                  stylingMode: 'filled',
                  placeholder: 'Prioridade',
                  valueExpr: 'Id',
                  displayExpr: 'Value',
                  dataSource: [
                    { Id: 0, Value: 'Baixa' },
                    { Id: 1, Value: 'Média' },
                    { Id: 2, Value: 'Alta' },
                    { Id: 3, Value: 'Urgente' },
                  ],
                  readOnly: false,
                }}
              >
                <Label visible={true} text={'Prioridade'} />
              </Item>
              <Item
                dataField={'TipSituacao'}
                editorType={'dxSelectBox'}
                editorOptions={{
                  stylingMode: 'filled',
                  placeholder: 'Situação',
                  valueExpr: 'Id',
                  displayExpr: 'Value',
                  dataSource: [
                    { Id: 0, Value: 'Aberto' },
                    { Id: 1, Value: 'Pendente' },
                    { Id: 2, Value: 'Resolvido' },
                    { Id: 3, Value: 'Fechado' },
                  ],
                  readOnly: false,
                }}
              >
                <Label visible={true} text={'Situação'} />
              </Item>
              <Item
                dataField={'DatAbertura'}
                editorType={'dxDateBox'}
                editorOptions={{
                  stylingMode: 'filled',
                  placeholder: 'Abertura',
                  readOnly: true,
                  type: 'datetime',
                }}
              >
                <Label visible={true} text={'Aberto em'} />
              </Item>
              <Item
                dataField={'DesTag'}
                editorType={'dxTextBox'}
                editorOptions={{
                  stylingMode: 'filled',
                  readOnly: false,
                }}
              >
                <Label visible={true} text={'Tags(Separe as palavras por vírgulas)'} />
              </Item>
            </GroupItem>
            <EmptyItem />
            <GroupItem colCount={6}>
              <EmptyItem />
              <EmptyItem />
              <EmptyItem />
              <EmptyItem />
              <EmptyItem />
              <ButtonItem>
                <ButtonOptions width={'100%'} type={'default'} useSubmitBehavior={true}>
                  <span className="dx-button-text">{props.loading ? <LoadIndicator width={'24px'} height={'24px'} visible={true} /> : 'Salvar'}</span>
                </ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </div>
      )}
    </form>
  )
}
