import React, { useEffect, useState } from 'react'
import Form, { Item, RequiredRule } from 'devextreme-react/form'
import { Button, DateBox, Validator, TextBox, TagBox } from 'devextreme-react'
import { api } from '../../api/api'
import { SelectBox } from 'devextreme-react/select-box'
import { Popup } from 'devextreme-react/popup'
import { useAuth } from '../../contexts/auth'
import { getToken, getRefreshTokenCode } from '../../api/auth'

import Alert from '@material-ui/lab/Alert'

import { useDataLoader } from '../../contexts/dataLoaderContext'

import moment from 'moment'

export default function DataLoader(props) {
  useEffect(() => {
    return async () => {
      SetShouldLoadOnRender(true)

      await setData([])
    }
  }, [])
  const { setLoading, setData, data, dlFilters, shouldLoadOnRender, SetShouldLoadOnRender } = useDataLoader()

  const [selectBoxWidth] = useState('fit-content')
  const [errorPopupMessage, setErrorPopupMessage] = useState('')
  const [popVisible, setPopVisible] = useState(false)
  const [errorPopupVisible, setErrorPopupVisible] = useState(false)
  const [, setReqErrorStatus] = useState(null)
  const { validateToken, valid, refreshToken } = useAuth()

  const [requiredRulesStatus, setRequiredRulesStatus] = useState(true)

  let filters = {}

  function mountFilters() {
    if (props.allowedFilters.date) {
      filters.initialDate = dlFilters.initialDate._d
      filters.finalDate = dlFilters.finalDate._d
    }
    if (props.allowedFilters.ticket) {
      filters.ticketId = dlFilters.ticketId
    }
    if (props.allowedFilters.client) {
      filters.clientId = dlFilters.clientId
    }
    if (props.allowedFilters.tecnician) {
      filters.tecnicianId = dlFilters.tecnicianId
    }
    if (props.allowedFilters.situation) {
      filters.situations = dlFilters.situations
    }
    if (props.allowedFilters.priority) {
      filters.priorities = dlFilters.priorities
    }
    if (props.allowedFilters.year) {
      filters.year = dlFilters.year
    }
    if (props.allowedFilters.month) {
      filters.month = dlFilters.month
    }
    if (props.allowedFilters.clientGroup) {
      filters.clientGroupId = dlFilters.clientGroupId
    }
    filters = { ...filters, ...props.baseFilters }
    dlFilters.setCurrentFilters(filters)
  }

  useEffect(() => {
    async function firstLoad() {
      if (typeof data !== 'undefined' && data.length != null) {
      }
      await validateToken(getToken())
    }
    firstLoad()
  }, [])

  function handleApiError(response) {
    let error = ''
    if (response) {
      setReqErrorStatus(response.status)

      if (response.status === 404) error = 'O recurso solicitado não foi encontrado.'
      if (response.status === 400) error = 'Houve um erro ao processar sua requisição.'
      if (response.status === 403) error = 'Você não tem permissão para visualizar está página, entre em contato com o administrador.'
      setErrorPopupMessage(`${error}`)
      setErrorPopupVisible(true)
    } else {
      error = 'O servidor demorou muito para retornar dados. Tente um intervalo menor.'
      setErrorPopupMessage(`${error}`)
      setErrorPopupVisible(true)
    }
  }

  useEffect(() => {
    async function loadData() {
      await api
        .get('/filter/clients')
        .then((response) => {
          dlFilters.setClients(response.data)
        })
        .catch((error) => {
          handleApiError(error.response)
        })
    }
    loadData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function loadData() {
      await api
        .get('/filter/contacts')
        .then((response) => {
          dlFilters.setContacts(response.data)
        })
        .catch((error) => {
          handleApiError(error.response)
        })
    }
    loadData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function loadData() {
      await api
        .get('/filter/reasons')
        .then((response) => {
          dlFilters.setReasons(response.data)
        })
        .catch((error) => {
          handleApiError(error.response)
        })
    }
    loadData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.allowedFilters.tecnician) {
      async function loadData() {
        await api
          .get('/filter/tecnicians')
          .then((response) => {
            dlFilters.setTecnicians(response.data)
          })
          .catch((error) => {
            handleApiError(error.response)
          })
      }
      loadData()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function loadData() {
      await api
        .get('/filter/clientGroups')
        .then((response) => {
          dlFilters.setClientGroups(response.data)
        })
        .catch((error) => {
          handleApiError(error.response)
        })
    }
    loadData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function loadData() {
      await api
        .get('/filter/teams')
        .then((response) => {
          dlFilters.setTeams(response.data)
        })
        .catch((error) => {
          handleApiError(error.response)
        })
    }
    loadData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  async function handleSubmit(e) {
    if (!props.url) return
    //setData([]);
    if (!shouldLoadOnRender) e.preventDefault()

    await validateToken(getToken())

    if (valid) {
      await refreshToken(getToken(), getRefreshTokenCode())
    }

    setLoading(true)
    mountFilters()
    await api
      .get(props.url, {
        params: filters,
      })
      .then((response) => {
        //console.log(response.data);
        setData(response.data)
        setLoading(false)
      })
      .catch((error) => {
        handleApiError(error.response)
        setLoading(false)
        // Error 😨
      })
  }

  useEffect(() => {
    if (shouldLoadOnRender) {
      function loadData() {
        handleSubmit()
        SetShouldLoadOnRender(false)
      }
      loadData()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // props.dataSource(data);
  // props.dataLoadingStatus(dataLoading);

  function changePopupVisibility() {
    popVisible === true ? setPopVisible(false) : setPopVisible(true)
  }

  function changeErrorPopupVisibility() {
    errorPopupVisible === true ? setErrorPopupVisible(false) : setErrorPopupVisible(true)
  }

  return (
    <React.Fragment>
      <form action="your-action" onSubmit={handleSubmit}>
        {!requiredRulesStatus && <Alert severity="error">Há filtros obrigatórios não preenchidos!</Alert>}
        <Button
          className="btn"
          text="Pesquisar"
          type="default"
          icon="find"
          visible={props.visible === undefined ? true : props.visible}
          useSubmitBehavior={true}
          stylingMode="contained"
          //onClick={handleSubmit}
        />
        <Button
          className="btn"
          icon="filter"
          visible={props.visible === undefined ? true : props.visible}
          stylingMode="text"
          text="Filtros"
          type="default"
          onClick={changePopupVisibility}
        />
      </form>
      <Popup
        className="pop-up"
        visible={popVisible}
        onHiding={changePopupVisibility}
        dragEnabled={true}
        resizeEnabled={true}
        closeOnOutsideClick={true}
        showTitle={true}
        title="Filtros"
        width={'fit-content'}
        height={'fit-content'}
      >
        <form
          action="your-action"
          onSubmit={(e) => {
            handleSubmit(e)
            changePopupVisibility()
          }}
        >
          <Form colCount={2} className="form">
            {props.allowedFilters.date && (
              <Item>
                <div className="dx-field-label">Data inicial</div>
                <DateBox
                  min={new Date(2020, 10, 1)}
                  width="max-content"
                  defaultValue={dlFilters.initialDate}
                  displayFormat={props.allowedFilters.date.format || 'dd/MM/yyyy HH:mm'}
                  type="datetime"
                  onValueChanged={(event) => {
                    dlFilters.setInitialDate(moment(event.value))
                  }}
                />
              </Item>
            )}
            {props.allowedFilters.date && (
              <Item>
                <div className="dx-field-label">Data Final</div>
                <DateBox
                  width="fit-content"
                  defaultValue={dlFilters.finalDate}
                  type="datetime"
                  displayFormat={props.allowedFilters.date.format || 'dd/MM/yyyy HH:mm'}
                  onValueChanged={(event) => dlFilters.setFinalDate(moment(event.value))}
                />
              </Item>
            )}
            {props.allowedFilters.ticket && (
              <Item>
                <div className="dx-field-label">Ticket</div>
                <TextBox
                  showClearButton={true}
                  className="checkBoxContainer"
                  placeholder="#20-0001"
                  value={dlFilters.ticketId}
                  onValueChanged={(event) => {
                    dlFilters.setTicketId(event.value)
                  }}
                >
                  {props.allowedFilters.ticket.required && (
                    <Validator
                      onValidated={(e) => {
                        setRequiredRulesStatus(e.isValid)
                      }}
                    >
                      <RequiredRule message={'Insira um ticket!'} />
                    </Validator>
                  )}
                </TextBox>
              </Item>
            )}

            {props.allowedFilters.client && (
              <Item>
                <div className="dx-field-label">Cliente</div>

                <SelectBox
                  width={selectBoxWidth}
                  searchEnabled={true}
                  showClearButton={true}
                  dataSource={dlFilters.clients}
                  dropDownOptions={{ width: 350 }}
                  //acceptCustomValue={true}
                  valueExpr="Id"
                  displayExpr="Value"
                  onValueChanged={(event) => {
                    dlFilters.setClientId(event.value)
                  }}
                >
                  {props.allowedFilters.client.required && (
                    <Validator
                      onValidated={(e) => {
                        setRequiredRulesStatus(e.isValid)
                      }}
                    >
                      <RequiredRule message={'Selecione um Cliente!'} />
                    </Validator>
                  )}
                </SelectBox>
              </Item>
            )}

            {props.allowedFilters.tecnician && (
              <Item>
                <div className="dx-field-label">Técnico</div>

                <SelectBox
                  width={selectBoxWidth}
                  searchEnabled={true}
                  showClearButton={true}
                  dataSource={dlFilters.tecnicians}
                  //acceptCustomValue={true}
                  valueExpr="Id"
                  displayExpr="Value"
                  onValueChanged={(event) => {
                    dlFilters.setTecnicianId(event.value)
                  }}
                >
                  {props.allowedFilters.tecnician.required && (
                    <Validator
                      onValidated={(e) => {
                        setRequiredRulesStatus(e.isValid)
                      }}
                    >
                      <RequiredRule message={'Selecione um Técnico!'} />
                    </Validator>
                  )}
                </SelectBox>
              </Item>
            )}

            {props.allowedFilters.situation && (
              <Item>
                <div className="dx-field-label">Situação</div>

                <TagBox
                  //searchEnabled={true}
                  showClearButton={true}
                  //selectionMode="multiple"
                  multiline={true}
                  dataSource={[
                    { Id: 0, Value: 'Aberto' },
                    { Id: 1, Value: 'Pendente' },
                    { Id: 2, Value: 'Resolvido' },
                    { Id: 3, Value: 'Fechado' },
                  ]}
                  //acceptCustomValue={true}
                  valueExpr="Id"
                  displayExpr="Value"
                  onInitialized={() => {
                    dlFilters.setSituations('0,1,2,3')
                  }}
                  defaultValue={[0, 1, 2, 3]}
                  showSelectionControls={true}
                  onValueChanged={(event) => {
                    let itens = ''
                    event.value.forEach((item) => {
                      itens = itens.concat(`${item},`)
                    })
                    itens = itens.slice(0, -1)
                    dlFilters.setSituations(itens)
                  }}
                >
                  {props.allowedFilters.situation.required && (
                    <Validator
                      onValidated={(e) => {
                        setRequiredRulesStatus(e.isValid)
                      }}
                    >
                      <RequiredRule message={'Selecione um Situação!'} />
                    </Validator>
                  )}
                </TagBox>
              </Item>
            )}

            {props.allowedFilters.priority && (
              <Item>
                <div className="dx-field-label">Prioridade</div>

                <TagBox
                  //width={400}
                  //searchEnabled={true}
                  showClearButton={true}
                  //selectionMode="multiple"

                  multiline={true}
                  defaultValue={[0, 1, 2, 3]}
                  dataSource={[
                    { Id: 0, Value: 'Baixa' },
                    { Id: 1, Value: 'Média' },
                    { Id: 2, Value: 'Alta' },
                    { Id: 3, Value: 'Urgente' },
                  ]}
                  //acceptCustomValue={true}
                  valueExpr="Id"
                  displayExpr="Value"
                  onInitialized={() => {
                    dlFilters.setPriorities('0,1,2,3')
                  }}
                  showSelectionControls={true}
                  onValueChanged={(event) => {
                    let itens = ''
                    event.value.forEach((item) => {
                      itens = itens.concat(`${item},`)
                    })
                    itens = itens.slice(0, -1)
                    dlFilters.setPriorities(itens)
                  }}
                >
                  {props.allowedFilters.priority.required && (
                    <Validator
                      onValidated={(e) => {
                        setRequiredRulesStatus(e.isValid)
                      }}
                    >
                      <RequiredRule message={'Selecione um Situação!'} />
                    </Validator>
                  )}
                </TagBox>
              </Item>
            )}

            {props.allowedFilters.year && (
              <Item>
                <div className="dx-field-label">Ano</div>

                <DateBox
                  showClearButton={true}
                  width="max-content"
                  displayFormat={'yyyy'}
                  style={{ minWidth: 150 }}
                  value={dlFilters.year}
                  calendarOptions={{
                    zoomLevel: 'decade',
                    maxZoomLevel: 'decade',
                    //minZoomLevel: "",
                  }}
                  // displayFormat={
                  //   props.allowedFilters.year.format || "dd/MM/yyyy HH:mm"
                  // }
                  onValueChanged={(event) => {
                    dlFilters.setYear(event.value)
                  }}
                >
                  {props.allowedFilters.year.required && (
                    <Validator
                      onValidated={(e) => {
                        setRequiredRulesStatus(e.isValid)
                      }}
                    >
                      <RequiredRule message={'Selecione um Ano!'} />
                    </Validator>
                  )}
                </DateBox>
              </Item>
            )}

            {props.allowedFilters.month && (
              <Item>
                <div className="dx-field-label">Mês</div>

                <DateBox
                  showClearButton={true}
                  width="max-content"
                  value={dlFilters.month}
                  calendarOptions={{
                    zoomLevel: 'year',
                    maxZoomLevel: 'year',
                    //minZoomLevel: "",
                  }}
                  // displayFormat={
                  //   props.allowedFilters.year.format || "dd/MM/yyyy HH:mm"
                  // }
                  onValueChanged={(event) => {
                    dlFilters.setMonth(event.value)
                  }}
                >
                  {props.allowedFilters.month.required && (
                    <Validator
                      onValidated={(e) => {
                        setRequiredRulesStatus(e.isValid)
                      }}
                    >
                      <RequiredRule message={'Selecione um Mês!'} />
                    </Validator>
                  )}
                </DateBox>
              </Item>
            )}

            {props.allowedFilters.clientGroup && (
              <Item>
                <div className="dx-field-label">Grupo de Clientes</div>

                <SelectBox
                  width={selectBoxWidth}
                  searchEnabled={true}
                  showClearButton={true}
                  dataSource={dlFilters.clientGroups}
                  valueExpr="Id"
                  displayExpr="Value"
                  onValueChanged={(event) => {
                    dlFilters.setClientGroupId(event.value)
                  }}
                >
                  {props.allowedFilters.clientGroup.required && (
                    <Validator
                      onValidated={(e) => {
                        setRequiredRulesStatus(e.isValid)
                      }}
                    >
                      <RequiredRule message={'Selecione um grupo de Clientes!'} />
                    </Validator>
                  )}
                </SelectBox>
              </Item>
            )}

            {props.baseFiltersComponents && props.baseFiltersComponents.map((item, index) => <Item key={index}>{item}</Item>)}
          </Form>
          <Button
            className="form-btn"
            text="Pesquisar"
            useSubmitBehavior={true}
            type="default"
            icon="find"
            stylingMode="contained"
            /*nClick={() => {
            handleSubmit();
            changePopupVisibility();
          }}*/
          />
        </form>
      </Popup>
      <Popup
        title="Erro"
        className="pop-up"
        visible={errorPopupVisible}
        onHiding={changeErrorPopupVisibility}
        dragEnabled={true}
        resizeEnabled={true}
        closeOnOutsideClick={true}
        showTitle={true}
        width={'fit-content'}
        height={'fit-content'}
      >
        <div>
          <h6>{errorPopupMessage}</h6>
        </div>

        <div>
          <Button
            className="form-btn"
            text="Ok"
            type="default"
            stylingMode="contained"
            onClick={() => {
              changeErrorPopupVisibility()
            }}
          />
        </div>
      </Popup>
    </React.Fragment>
  )
}
