import { Card, CardContent, makeStyles } from '@material-ui/core'
import { Form } from 'devextreme-react'
import { ButtonItem, ButtonOptions, EmptyItem, GroupItem, Item, Label, RequiredRule } from 'devextreme-react/form'
import { formatMessage } from 'devextreme/localization'
import notify from 'devextreme/ui/notify'
import React, { useRef, useState } from 'react'
import { api } from '../../api/api'
import { useAuth } from '../../contexts/auth'
import { useDataLoader } from '../../contexts/dataLoaderContext'

export default () => {
  const [shouldReload, setShouldReload] = useState(true)
  const { user } = useAuth()
  const ticket = Cache['ticket']
  const ref = useRef()
  const { loading, dlFilters, newTicketMessageFormVisible, setNewTicketMessageFormVisible } = useDataLoader()

  const useStyles = makeStyles({
    root: {
      maxWidth: '100%',
      minWidth: 345,
      minHeight: 60,
      margin: '4px 14% 1% 2.5%',
      float: 'none',
    },
  })

  const classes = useStyles()

  const addMessage = (data) => {
    api
      .post('ticket/answers', data)
      .then((response) => {
        notify(formatMessage('UpdatedSuccess'), 'success', 3000)
        setNewTicketMessageFormVisible('none')
        setShouldReload(!shouldReload)
      })
      .catch((error) => {
        notify(formatMessage('UpdateError'), 'error', 3000)
      })
  }

  return (
    <div style={{ display: newTicketMessageFormVisible }}>
      <Card key={22} className={classes.root}>
        <CardContent>
          <CardContent>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                const data = ref.current.props.formData
                addMessage(data)
              }}
            >
              <Form
                colCount={1}
                formData={{
                  CodTicket: ticket.CodTicket,
                  TipPublica: true,
                  CodClienteContato: user.CodClienteContato,
                  CodCliente: user.CodClienteGrupo !== null ? 0 : user.CodCliente,
                }}
                ref={ref}
              >
                <Item
                  dataField={'CodTicket'}
                  editorType={'dxTextBox'}
                  editorOptions={{
                    stylingMode: 'filled',
                    readOnly: true,
                    visible: false,
                  }}
                >
                  <Label visible={false} text={'Contato'} />
                </Item>

                <Item
                  dataField={'DesTicketResposta'}
                  editorType={'dxTextArea'}
                  style={{ height: 700 }}
                  editorOptions={{
                    stylingMode: 'filled',
                    style: { height: 700 },
                    readOnly: false,
                  }}
                >
                  <RequiredRule />
                  <Label visible={true} text={'Mensagem'} />
                </Item>
                <GroupItem colCount={7}>
                  <EmptyItem />
                  <EmptyItem />
                  <EmptyItem />
                  <EmptyItem />
                  <EmptyItem />
                  <ButtonItem>
                    <ButtonOptions
                      type={'default'}
                      text="Cancelar"
                      onClick={(e) => {
                        setNewTicketMessageFormVisible('none')
                      }}
                      icon="close"
                      useSubmitBehavior={false}
                    ></ButtonOptions>
                  </ButtonItem>
                  <ButtonItem>
                    <ButtonOptions type={'default'} text="Salvar" icon="save" useSubmitBehavior={true}></ButtonOptions>
                  </ButtonItem>
                </GroupItem>
              </Form>
            </form>
          </CardContent>
        </CardContent>
      </Card>
    </div>
  )
}
