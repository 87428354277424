import React, { useState, useRef } from 'react'
import 'devextreme/data/odata/store'
import Form from 'devextreme-react/form'
import { Item } from 'devextreme-react/box'
import '../../components/data-loader/style.scss'

import clsx from 'clsx'

import { useDataLoader } from '../../contexts/dataLoaderContext'
import { Box } from 'devextreme-react'
import moment from 'moment'
import { Card, makeStyles, CardActionArea, CardContent, Typography, useTheme, Chip, Collapse, IconButton, Divider } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { useHistory } from 'react-router-dom'

export default (props) => {
  const theme = useTheme()
  const [expanded, setExpanded] = React.useState(false)

  const { loading, data, dlFilters, SetShouldLoadOnRender } = useDataLoader()
  const history = useHistory()
  const [currentTicket, setCurrentTicket] = useState('')

  const getColorChipSituation = (item) => {
    const colors = {
      0: 'grey',
      1: '#FF9F1A',
      2: '#61BD4F',
      3: '#eb5a46',
    }

    return colors[item.TipSituacao]
  }
  const getColorChipPriority = (item) => {
    const colors = {
      0: '#61BD4F',
      1: '#f4de33',
      2: '#FF9F1A',
      3: '#eb5a46',
    }

    return colors[item.TipPrioridade]
  }

  const getIcon = (item) => {
    const icons = {
      0: <KeyboardArrowDownIcon style={{ color: getColorChipSituation(item) }} />,
      1: <HourglassEmptyIcon style={{ color: getColorChipSituation(item) }} />,
      2: <CheckIcon style={{ color: getColorChipSituation(item) }} />,
      3: <CloseIcon style={{ color: getColorChipSituation(item) }} />,
    }

    return icons[item.TipSituacao]
  }

  const getDate = (date) => {
    const newDate = `${moment(date).format('L')} ${moment(date).format('LT')}`

    return newDate
  }
  const useStyles = makeStyles({
    root: {
      maxWidth: '100%',
      width: '100%',
      margin: '4px 0% 1% 1%',
      float: 'left',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shorter,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    chip: {
      marginTop: '1%',
      // display: "flex",
      justifyContent: 'left',

      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  })
  const classes = useStyles()
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  return (
    <div className="form-container" style={{ display: 'flex', flexWrap: 'wrap' }}>
      {Array.isArray(data) &&
        data.length !== undefined &&
        data.map((item) => (
          <Card key={item.CodTicket} className={classes.root} defaultValue={item.CodTicket}>
            <CardContent>
              <Box direction="row" width={'auto'} height={'auto'}>
                <Item ratio={0} baseSize={'94%'}>
                  <CardActionArea
                    onClick={(e) => {
                      SetShouldLoadOnRender(true)
                      dlFilters.setTicketId(item.CodTicket)
                      Cache['ticket'] = item
                      history.push('/ticket_detailed')
                    }}
                  >
                    <Box direction="row" width={'auto'} height={'auto'}>
                      <Item ratio={0} baseSize={'20%'}>
                        <Typography
                          style={{
                            marginTop: '2%',
                          }}
                          variant="body2"
                          color="initial"
                        >
                          {item.CodTicket}{' '}
                        </Typography>
                      </Item>

                      <Item ratio={0} baseSize={'80%'}>
                        <Typography
                          //align={"center"}
                          variant="body1"
                          component="p"
                        >
                          {item.DesAssunto}
                        </Typography>
                      </Item>
                      <Item ratio={0} baseSize={'35%'}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{
                            marginTop: '0.1%',
                          }}
                          component="p"
                        >
                          Aberto em {getDate(item.DatAbertura)} - {moment(item.DatAbertura).fromNow()}
                        </Typography>
                      </Item>
                    </Box>
                  </CardActionArea>
                </Item>

                <Item ratio={0} baseSize={'1%'}>
                  <IconButton
                    onMouseOver={(e) => {}}
                    value={item.CodTicket}
                    key={item.CodTicket}
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: item.CodTicket === currentTicket && expanded,
                    })}
                    onClick={() => {
                      setCurrentTicket(item.CodTicket)
                      handleExpandClick()
                    }}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </Item>
              </Box>
              <Divider style={{ margin: '0% 1% 1.5% 1%' }} />
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ margin: '0 20px 0 0', widht: '10%' }}>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Responsável:
                  </Typography>
                  <Typography style={{ marginTop: '8%' }} variant="body2" color="textPrimary" component="p">
                    {item.NomUsuario}
                  </Typography>
                </div>
                <div style={{ width: '20%' }}>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Cliente:
                  </Typography>

                  <Typography style={{ marginTop: '2%' }} variant="body2" color="textPrimary" component="p">
                    {item.NomCliente}
                  </Typography>
                </div>
                <div style={{ width: '30%' }}>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Tags:
                  </Typography>
                  <div className={classes.chip}>
                    {item.Tags?.split(',').map((tag) => (
                      <Chip key={tag} label={tag} variant={'outlined'} />
                    ))}
                  </div>
                </div>
                <div>
                  <Chip
                    label={item.Prioridade}
                    variant={'outlined'}
                    clickable={true}
                    onClick={(e) => {}}
                    style={{
                      marginTop: '13%',
                      marginRight: 20,
                      color: getColorChipPriority(item),
                      borderColor: getColorChipPriority(item),
                    }}
                    icon={<FiberManualRecordIcon style={{ color: getColorChipPriority(item) }} />}
                  />
                  <Chip
                    label={item.Situacao}
                    variant={'outlined'}
                    clickable={true}
                    style={{
                      marginTop: '13%',
                      color: getColorChipSituation(item),
                      borderColor: getColorChipSituation(item),
                    }}
                    icon={getIcon(item)}
                  />
                </div>
              </div>
            </CardContent>

            <Collapse in={item.CodTicket === currentTicket && expanded} timeout="auto" collapsedHeight={20} unmountOnExit>
              <CardContent>
                <Typography align={'left'} variant="body2" color="textSecondary" component="p">
                  Última resposta em {getDate(item.DataUltimaResposta)} - {moment(item.DataUltimaResposta).fromNow()}
                </Typography>
                <Typography paragraph>{item.UltimaResposta}</Typography>
              </CardContent>
            </Collapse>
          </Card>
        ))}
    </div>
  )
}
