import React, { useEffect, useRef, useState } from 'react'
import 'devextreme/data/odata/store'
import { Item } from 'devextreme-react/box'
import '../../components/data-loader/style.scss'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import DataLoader from '../../components/data-loader/DataLoader'
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Selection,
  ColumnChooser,
  ColumnFixing,
  Export,
  Item as ItemGrid,
  Toolbar,
} from 'devextreme-react/data-grid'

import { useDataLoader } from '../../contexts/dataLoaderContext'
import { Box } from 'devextreme-react'
import { api } from '../../api/api'
import { Typography } from '@material-ui/core'

import { Button } from 'devextreme-react'
import { useHistory } from 'react-router-dom'
import { formatMessage } from 'devextreme/localization'
import { onToolbarPreparingExportDataGridToPDF, renderExportDataGridToPDFButton } from '../../utils/exportGridToPDF'
import {
  onToolbarPreparingExportDataGridToExcel,
  renderExportMasterGridToExcelButton,
} from '../../utils/exportMasterGridToExcel'
import { Template } from 'devextreme-react/core/template'

export default function AppointmentsDetailed(props) {
  const { loading, dlFilters, SetShouldLoadOnRender } = useDataLoader()
  const [appointments, setAppointments] = useState([])
  const history = useHistory()
  const _data = useRef()

  useEffect(() => {
    const appointment = Cache['appointment']

    async function loadData() {
      await api
        .get('ticket/AppointmentsDetailed', {
          params: {
            year: appointment.Key,
            month: appointment.Key,
            clientId: appointment.CodCliente,
            count: true,
          },
        })
        .then((response) => {
          setAppointments(response.data)
        })
    }

    if (appointment) loadData()
  }, [])

  return (
    <React.Fragment>
      <Button
        style={{ float: 'left' }}
        type={'back'}
        icon={'chevronleft'}
        className='content-block'
        onClick={() => {
          SetShouldLoadOnRender(true)
          props.history.goBack()
        }}
      />
      <Typography className='content-block' variant={'h2'}>
        Apontamentos por Cliente
      </Typography>
      <div className='form-container'>
        <Box style={{ margin: '0 0 0 2%' }} width='89%' height='auto'>
          <Item ratio={0} baseSize={'90%'}>
            <DataLoader
              url={'ticket/appointments'}
              visible={false}
              allowedFilters={{
                year: {},
                client: {},
                clientGroup: {},
              }}
            />
          </Item>
        </Box>
      </div>
      <div id='grid'>
        <DataGrid
          style={{ margin: '2% 2.6% 2% 2.6%' }}
          className={'dx-card'}
          dataSource={appointments}
          rowAlternationEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          showBorders={false}
          disabled={loading}
          //focusedRowEnabled={true}
          onSelectionChanged={(e) => {}}
          //keyExpr={"color"}
          defaultFocusedRowIndex={0}
          wordWrapEnabled={true}
          //columnAutoWidth={true}
          columnHidingEnabled={true}
          headerFilter={{ visible: true }}
          filterPanel={{ visible: true }}
          filterRow={{ visible: true }}
          ref={_data}
        >
          <ColumnChooser enabled={true} mode='select' />
          <ColumnFixing enabled={false} />
          <Selection mode='single' />
          <Paging defaultPageSize={20} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <FilterRow visible={true} />

          <Column
            widht={'fit-content'}
            dataField={'NomApresentacaoUsuario'}
            dataType='string'
            caption={'Responsável'}
            hidingPriority={12}
          />

          <Column
            dataField={'DatInicialTarefaApontamento'}
            dataType='datetime'
            caption={'Inicio Tarefa'}
            hidingPriority={9}
          />
          <Column
            dataField={'DatFinalTarefaApontamento'}
            dataType='datetime'
            caption={'Fim Tarefa'}
            hidingPriority={8}
          />
          <Column dataField={'Duracao'} dataType='string' caption={'Tempo Total'} hidingPriority={10} />
          <Column dataField={'NomClienteGrupo'} dataType='string' caption={'Grupo'} hidingPriority={6} />
          <Column dataField={'NomCliente'} dataType='string' caption={'Cliente'} hidingPriority={5} />
          <Column dataField={'DesTarefaApontamento'} dataType='string' caption={'Descrição'} hidingPriority={1} />

          <Column dataField={'DesSolucaoSuporte'} dataType='string' caption={'Solução'} hidingPriority={2} />
          <Column
            width={'auto'}
            caption={'Ticket'}
            hidingPriority={13}
            cellRender={(e) => {
              return (
                e.data.CodTicket && (
                  <div className='chart-cell'>
                    <Button
                      height={30}
                      text={formatMessage('Ticket')}
                      onClick={() => {
                        dlFilters.setTicketId(e.data.CodTicket)
                        history.push('/tickets')
                      }}
                    />
                  </div>
                )
              )
            }}
          />
          <Toolbar>
            <ItemGrid
              name='renderButtonPDF'
              render={() => renderExportDataGridToPDFButton(_data, dlFilters.initialDate, dlFilters.finalDate)}
            />
            <ItemGrid
              name='renderButtonExcel'
              render={() =>
                renderExportMasterGridToExcelButton(_data, false, dlFilters.initialDate, dlFilters.finalDate)
              }
            />
            <ItemGrid name='columnChooserButton' />
          </Toolbar>
        </DataGrid>
      </div>
      <div className='load-indicator'>
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  )
}
