import { formatMessage } from "devextreme/localization";
import ticket from "./icons/confirmation_number-white-48dp.svg";
import clock from "./icons/update-white-18dp.svg";
import asset from "./icons/assessment-white-18dp.svg";
import zodiac from "./icons/zodiaco.svg";

export const navigation = [
  {
    text: () => {
      return formatMessage("Home");
    },
    path: "/home_intern",
    icon: "home",
  },

  {
    text: () => {
      return formatMessage("Home");
    },
    path: "/home_client_manager",
    icon: "home",
  },
  {
    text: () => {
      return formatMessage("Home");
    },
    path: "/home_client",
    icon: "home",
  },
  {
    icon: "user",
    text: () => {
      return "Perfil";
    },
    path: "/profile",
  },
  {
    icon: ticket,
    text: () => {
      return formatMessage("Ticket");
    },
    path: "/tickets",
  },
  {
    icon: clock,
    text: () => {
      return formatMessage("Appointments");
    },
    path: "/appointments",
  },
  {
    icon: asset,
    text: () => {
      return `${formatMessage("DetailedAppointments")}`;
    },
    path: "/appointments_detailed",
  },
  {
    icon: "user",
    text: () => {
      return `${formatMessage("Contact")}s`;
    },
    path: "/contacts",
  },
  {
    icon: "folder",
    text: () => {
      return `${formatMessage("Training")}`;
    },
    path: "/training",
  },
];
