import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import React, { useEffect } from "react";
import { HashRouter as Router } from "react-router-dom";
import "./dx-styles.scss";
import LoadPanel from "devextreme-react/load-panel";
import { NavigationProvider } from "./contexts/navigation";
import { AuthProvider, useAuth } from "./contexts/auth";
import { useScreenSizeClass } from "./utils/media-query";
import Content from "./Content";
import NotAuthenticatedContent from "./NotAuthenticatedContent";
import { locale, loadMessages } from "devextreme/localization";
import ptMessages from "devextreme/localization/messages/pt.json";
import enMessages from "devextreme/localization/messages/en.json";
import { DataLoaderProvider } from "./contexts/dataLoaderContext";
import service from "./localization/dictionary";
import { codeToString } from "./localization/languagesEnum";
import moment from "moment";
import localization from "moment/locale/pt-br";
import {
  LocalizationProvider,
  useLocalization,
} from "./contexts/localizationContext";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles'
import { getToken, isAuthenticated } from "./api/auth";

function App() {
  const { user, loading, validateToken, valid } = useAuth();
  const { LANGUAGE_KEY, setInitialLanguage } = useLocalization();
  useEffect(() => {
    moment.locale(localStorage.getItem(LANGUAGE_KEY) === "en" ? "en" : "pt-br");
    loadMessages(service.getDictionary());
    loadMessages(enMessages);
    loadMessages(ptMessages);
    //setInitialLanguage(codeToString[initialConfig.defaultLanguage]);
    locale(localStorage.getItem(LANGUAGE_KEY) || "pt");
    async function valid() {
      await validateToken(getToken());
    }
    valid();
  }, [LANGUAGE_KEY, validateToken]);

  //setInitialLanguage(codeToString[initialConfig.defaultLanguage]);

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (isAuthenticated() && valid) {
    return <Content />;
  }

  return <NotAuthenticatedContent />;
}

export default function () {
  const screenSizeClass = useScreenSizeClass();
  const darkTheme = createTheme({
    palette: {
      type: "dark",
      background: { paper: "#363640" },
    },
  });

  return (
    <Router>
      <AuthProvider>
        <DataLoaderProvider>
          <LocalizationProvider>
            <ThemeProvider theme={darkTheme}>
              <NavigationProvider>
                <div className={`app ${screenSizeClass}`}>
                  <App />
                </div>
              </NavigationProvider>
            </ThemeProvider>
          </LocalizationProvider>
        </DataLoaderProvider>
      </AuthProvider>
    </Router>
  );
}
