const dictionary = {
  en: {
    Home: "Home",
    About: "About",
    Date: "Date",
    Ticket: "Ticket",
    DetailedTicket: "Detailed Ticket",
    Appointments: "Appointments",
    Report: "Report",
    DetailedAppointments: "Detailed Appointments",
    OpenedToday: "Opened Today",
    OpenedLast7Days: "Opened in the last 7 days",
    OpenedMoreThan7Days: "Open for more than 7 days",
    LessThanYersterday: "less than yersterday",
    MoreThanYersterday: "more than yersterday",
    LessThanLastWeek: "less than lastweek",
    MoreThanLastWeek: "more than lastweek",
    Client: "Client",
    Contact: "Contact",
    Training: "Internal Trainings",
    InsertedSuccess: "Insert with sucess",
    UpdatedSuccess: "Updated with sucess",
    InsertError: "Error at insert",
    UpdateError: "Error at update",
  },
  pt: {
    Home: "Início",
    About: "Sobre",
    Date: "Data",
    Ticket: "Ticket",
    DetailedTicket: "Ticket Detalhado",
    Appointments: "Apontamentos",
    Report: "Relatório",
    DetailedAppointments: "Apontamentos Detalhados",
    OpenedToday: "Abertos Hoje",
    OpenedLast7Days: "Abertos nos últimos 7 dias",
    OpenedMoreThan7Days: "Abertos a mais de 7 dias",
    LessThanYersterday: "inferior a ontem",
    MoreThanYersterday: "superior a ontem",
    LessThanLastWeek: "inferior a semana passada",
    MoreThanLastWeek: "superior a semana passada",
    Client: "Cliente",
    Contact: "Contato",
    Training: "Treinamentos Internos",
    InsertedSuccess: "Inserido com sucesso",
    UpdatedSuccess: "Atualizado com sucesso",
    InsertError: "Erro ao inserir",
    UpdateError: "Erro ao atualizar",
  },
};

export default {
  getDictionary() {
    return dictionary;
  },
};
