import React, { useRef, useState } from 'react'
import { Card, CardContent, Divider, Typography } from '@material-ui/core'
import { Form, LoadIndicator } from 'devextreme-react'
import { ButtonItem, ButtonOptions, EmptyItem, GroupItem, Item, Label, RequiredRule } from 'devextreme-react/form'
import { api } from '../../api/api'
import notify from 'devextreme/ui/notify'
import { formatMessage } from 'devextreme/localization'
import { useDataLoader } from '../../contexts/dataLoaderContext'
import userEvent from '@testing-library/user-event'
import { useAuth } from '../../contexts/auth'

export default (props) => {
  const [clientGroupId, setClientGroupId] = useState(0)
  const { dlFilters, setNewTicketFormVisible, newTicketFormVisible, SetShouldLoadOnRender } = useDataLoader()
  const { user } = useAuth()
  const [loading, setLoading] = useState()

  async function createTicket(data) {
    data.TipPublica = true
    data.CodClienteContato = user.CodClienteContato
    data.TipSituacao = 0
    data.TicketResposta = [
      {
        CodTicket: data.CodTicket,
        DatTicketResposta: data.DatAbertura,
        CodClienteContato: data.CodClienteContato,
        CodUsuarioContato: data.CodUsuarioContato,
        TipPublica: data.TipPublica,
        DesTicketResposta: data.DesTicketResposta,
      },
    ]
    return await api
      .post('/ticket', data)
      .then((response) => {
        notify(formatMessage('UpdatedSuccess'), 'success', 3000)
        setLoading(false)
        setNewTicketFormVisible('none')
        return false
      })
      .catch((error) => {
        notify(formatMessage('UpdateError'), 'error', 3000)
        setLoading(false)
        return true
      })
  }

  const ref = useRef()

  return (
    <form
      className={'create-account-form'}
      onSubmit={(e) => {
        e.preventDefault()
        const data = ref.current.props.formData
        createTicket(data)
        window.location.reload()
      }}
    >
      <div style={{ display: newTicketFormVisible }}>
        <Card>
          <CardContent>
            <Form
              di
              formData={{
                DatAbertura: new Date(),
                TipSituacao: 0,
                TipPrioridade: 0,
                TipPublica: true,
                CodCliente: user.CodClienteGrupo !== null ? 0 : user.CodCliente,
              }}
              //disabled={props.loading}
              colCount={1}
              width={'100%'}
              height={'100%'}
              ref={ref}
            >
              <Item
                dataField={'DesAssunto'}
                editorType={'dxTextBox'}
                editorOptions={{
                  stylingMode: 'filled',
                  readOnly: false,
                  maxLength: 100,
                }}
              >
                <RequiredRule />
                <Label visible={true} text={'Assunto'} />
              </Item>
              <GroupItem colCount={4}>
                <Item
                  dataField={'CodCliente'}
                  editorType={'dxSelectBox'}
                  editorOptions={{
                    stylingMode: 'filled',
                    valueExpr: 'Id',
                    displayExpr: 'Value',
                    dataSource: dlFilters.clients.filter((x) => (user.CodClienteGrupo !== null ? x.CodClienteGrupo === user.CodClienteGrupo : x.Id === user.CodCliente)),
                    readOnly: false,
                  }}
                >
                  <RequiredRule />
                  <Label visible={true} text={'Cliente'} />
                </Item>

                <Item
                  dataField={'TipPrioridade'}
                  editorType={'dxSelectBox'}
                  editorOptions={{
                    stylingMode: 'filled',
                    placeholder: 'Prioridade',
                    valueExpr: 'Id',
                    displayExpr: 'Value',
                    dataSource: [
                      { Id: 0, Value: 'Baixa' },
                      { Id: 1, Value: 'Média' },
                      { Id: 2, Value: 'Alta' },
                      { Id: 3, Value: 'Urgente' },
                    ],
                    readOnly: false,
                  }}
                >
                  <RequiredRule />
                  <Label visible={true} text={'Prioridade'} />
                </Item>

                <Item
                  dataField={'DatAbertura'}
                  editorType={'dxDateBox'}
                  editorOptions={{
                    stylingMode: 'filled',
                    readOnly: true,
                    type: 'datetime',
                  }}
                >
                  <Label visible={true} text={'Aberto em'} />
                </Item>
              </GroupItem>
              <GroupItem caption="Mensagem">
                <Item
                  dataField={'CodTicket'}
                  editorType={'dxTextBox'}
                  editorOptions={{
                    stylingMode: 'filled',
                    readOnly: true,
                    visible: false,
                  }}
                >
                  <Label visible={false} text={'Contato'} />
                </Item>

                <Item
                  dataField={'DesTicketResposta'}
                  editorType={'dxTextArea'}
                  style={{ height: 700 }}
                  editorOptions={{
                    stylingMode: 'filled',
                    style: { height: 700 },
                    readOnly: false,
                  }}
                >
                  <RequiredRule />
                  <Label visible={true} text={'Mensagem'} />
                </Item>
              </GroupItem>
              <EmptyItem />
              <GroupItem colCount={8}>
                <EmptyItem />
                <EmptyItem />
                <EmptyItem />
                <EmptyItem />
                <EmptyItem />
                <EmptyItem />
                <ButtonItem>
                  <ButtonOptions
                    width={'100%'}
                    type={'default'}
                    onClick={() => {
                      setLoading(true)
                      setNewTicketFormVisible('none')
                    }}
                    useSubmitBehavior={false}
                    text={'Cancelar'}
                  ></ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                  <ButtonOptions width={'100%'} type={'default'} useSubmitBehavior={true}>
                    <span className="dx-button-text">Salvar</span>
                  </ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </CardContent>
        </Card>
      </div>
    </form>
  )
}
