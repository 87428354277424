import React, { useEffect, useRef, useCallback, useMemo } from "react";
import TreeView from "devextreme-react/tree-view";
import { navigation } from "../../app-navigation";
import { useNavigation } from "../../contexts/navigation";
import { useScreenSize } from "../../utils/media-query";
import "./side-navigation-menu.scss";
import routes from "../../app-routes";
import { logout, getUser } from "../../api/auth";

import * as events from "devextreme/events";
import { useAuth } from "../../contexts/auth";

export default function (props) {
  const {
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady,
  } = props;

  const { user, refreshToken, setNav } = useAuth();
  let nav = navigation;

  const checkPermissionPerRoute = (route, haveChild) => {
    let remove = true;
    let newItems = [];
    user.roles.forEach((x) => {
      if (remove) {
        if (route.roles && route.roles.indexOf(x.NomPermissao) === -1) {
          remove = true;
        } else {
          remove = false;
        }
      }
    });
    if (remove) {
      if (!haveChild) {
        nav = nav.filter((x) => x.path !== route.path);
      } else {
        nav.forEach((x) => {
          x.items = x.items?.filter((x) => x.path !== route.path);
        });
      }
    }
  };
  const checkPermissions = async () => {
    nav.forEach(async (item) => {
      if (item.path) {
        var route = routes.find((x) => x.path === item.path);
        checkPermissionPerRoute(route, false);
      } else {
        item.items.forEach((item) => {
          var route = routes.find((x) => x.path === item.path);
          checkPermissionPerRoute(route, true);
        });
      }
    });
    setNav(nav);
  };

  const { isLarge } = useScreenSize();
  function normalizePath() {
    checkPermissions();

    return nav.map((item) => {
      if (item.path && !/^\//.test(item.path)) {
        item.path = `/${item.path}`;
      }
      return { ...item, expanded: isLarge };
    });
  }

  const items = useMemo(
    normalizePath,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    navigationData: { currentPath },
  } = useNavigation();

  const treeViewRef = useRef();
  const wrapperRef = useRef();
  const getWrapperRef = useCallback(
    (element) => {
      const prevElement = wrapperRef.current;
      if (prevElement) {
        events.off(prevElement, "dxclick");
      }

      wrapperRef.current = element;
      events.on(element, "dxclick", (e) => {
        openMenu(e);
      });
    },
    [openMenu]
  );

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode]);

  return (
    <div
      className={"dx-swatch-additional side-navigation-menu"}
      ref={getWrapperRef}
    >
      {children}
      <div className={"menu-container"}>
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr={"path"}
          selectionMode={"single"}
          focusStateEnabled={false}
          expandEvent={"click"}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={"100%"}
        />
      </div>
    </div>
  );
}
