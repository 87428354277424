import React, { useState, useRef } from 'react'
import 'devextreme/data/odata/store'
import Form from 'devextreme-react/form'
import { Item } from 'devextreme-react/box'
import '../../components/data-loader/style.scss'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import DataLoader from '../../components/data-loader/DataLoader'
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Selection,
  ColumnChooser,
  ColumnFixing,
  Item as ItemGrid,
  Toolbar,
} from 'devextreme-react/data-grid'
import clsx from 'clsx'

import { useDataLoader } from '../../contexts/dataLoaderContext'
import { Box, Button, SelectBox } from 'devextreme-react'
import moment from 'moment'
import {
  Card,
  makeStyles,
  CardActionArea,
  CardContent,
  Typography,
  CardActions,
  useTheme,
  Chip,
  Collapse,
  IconButton,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { useHistory } from 'react-router-dom'
import { onToolbarPreparingExportDataGridToPDF, renderExportDataGridToPDFButton } from '../../utils/exportGridToPDF'
import { Template } from 'devextreme-react/core/template'
import {
  onToolbarPreparingExportDataGridToExcel,
  renderExportMasterGridToExcelButton,
} from '../../utils/exportMasterGridToExcel'

export default (props) => {
  const theme = useTheme()
  const _data = useRef()
  const { loading, data, dlFilters, SetShouldLoadOnRender } = useDataLoader()
  const history = useHistory()
  const getColorChipSituation = (item) => {
    const colors = {
      0: 'grey',
      1: '#FF9F1A',
      2: '#61BD4F',
      3: '#eb5a46',
    }

    return colors[item.TipSituacao]
  }
  const getColorChipPriority = (item) => {
    const colors = {
      0: '#61BD4F',
      1: '#f4de33',
      2: '#FF9F1A',
      3: '#eb5a46',
    }

    return colors[item.TipPrioridade]
  }

  const getIcon = (item) => {
    const icons = {
      0: <KeyboardArrowDownIcon style={{ color: getColorChipSituation(item) }} />,
      1: <HourglassEmptyIcon style={{ color: getColorChipSituation(item) }} />,
      2: <CheckIcon style={{ color: getColorChipSituation(item) }} />,
      3: <CloseIcon style={{ color: getColorChipSituation(item) }} />,
    }

    return icons[item.TipSituacao]
  }

  const useStyles = makeStyles({
    root: {
      maxWidth: '100%',
      width: '100%',
      margin: '4px 5px 10px',
      float: 'left',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shorter,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    chip: {
      marginTop: '2%',
      // display: "flex",
      justifyContent: 'left',

      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  })
  const classes = useStyles()

  return (
    <DataGrid
      className={'dx-card'}
      dataSource={data}
      rowAlternationEnabled={true}
      //allowColumnResizing={true}
      allowColumnReordering={true}
      showBorders={false}
      disabled={loading}
      //focusedRowEnabled={true}
      onRowClick={(e) => {
        SetShouldLoadOnRender(true)
        dlFilters.setTicketId(e.data.CodTicket)
        Cache['ticket'] = e.data
        history.push('/ticket_detailed')
      }}
      onSelectionChanged={(e) => {}}
      //keyExpr={"color"}
      defaultFocusedRowIndex={0}
      columnAutoWidth={true}
      columnHidingEnabled={true}
      headerFilter={{ visible: true }}
      filterPanel={{ visible: true }}
      filterRow={{ visible: true }}
      ref={_data}
    >
      <ColumnChooser enabled={true} mode='select' />
      <ColumnFixing enabled={false} />
      <Selection mode='single' />
      <Paging defaultPageSize={20} />
      <Pager showPageSizeSelector={true} showInfo={true} />
      <FilterRow visible={true} />
      <Column dataField={'CodTicket'} dataType='string' caption={'Cód.'} hidingPriority={11} />
      <Column dataField={'NomUsuario'} dataType='string' caption={'Responsável'} hidingPriority={10} />
      <Column dataField={'NomCliente'} dataType='string' caption={'Cliente'} hidingPriority={9} />

      <Column
        dataField={'Tags'}
        dataType='string'
        caption={'Tags'}
        width={'auto'}
        cellRender={(e) => {
          return (
            <div className={classes.chip}>
              {e.data.Tags?.split(',').map((tag) => (
                <Chip key={tag} label={tag} variant={'outlined'} />
              ))}
            </div>
          )
        }}
        hidingPriority={5}
      />
      <Column
        dataField={'DesAssunto'}
        dataType='string'
        caption={'Assunto'}
        //cssClass="word-wrap"
        hidingPriority={6}
      />

      <Column
        dataField={'DatAbertura'}
        dataType='datetime'
        caption={'Data de Abertura'}
        //cssClass="word-wrap"
        hidingPriority={7}
      />
      <Column
        dataField={'Prioridade'}
        dataType='string'
        caption={'Prioridade'}
        cellRender={(e) => {
          return (
            <div className='chart-cell'>
              <Chip
                label={e.data.Prioridade}
                variant={'outlined'}
                clickable={true}
                style={{
                  color: getColorChipPriority(e.data),
                  borderColor: getColorChipPriority(e.data),
                }}
                icon={<FiberManualRecordIcon style={{ color: getColorChipPriority(e.data) }} />}
              />
            </div>
          )
        }}
        hidingPriority={1}
      />
      <Column
        dataField={'Situacao'}
        dataType='string'
        caption={'Situação'}
        cellRender={(e) => {
          return (
            <div className='chart-cell'>
              <Chip
                label={e.data.Situacao}
                variant={'outlined'}
                clickable={true}
                style={{
                  color: getColorChipSituation(e.data),
                  borderColor: getColorChipSituation(e.data),
                }}
                icon={getIcon(e.data)}
              />
            </div>
          )
        }}
        hidingPriority={1}
      />
      <Column
        dataField={'DataUltimaResposta'}
        dataType='datetime'
        caption={'Data Última Resposta'}
        //cssClass="word-wrap"
        hidingPriority={2}
      />
      <Column
        dataField={'UltimaResposta'}
        dataType='datetime'
        caption={'Última Resposta'}
        //cssClass="word-wrap"
        hidingPriority={1}
      />
      <Toolbar>
        <ItemGrid
          name='renderButtonPDF'
          render={() => renderExportDataGridToPDFButton(_data, props.initialDate, props.finalDate)}
        />
        <ItemGrid
          name='renderButtonExcel'
          render={() => renderExportMasterGridToExcelButton(_data, false, props.initialDate, props.finalDate)}
        />
        <ItemGrid name='columnChooserButton' />
      </Toolbar>
    </DataGrid>
  )
}
