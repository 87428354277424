import React, { useRef, useState } from "react";
import "devextreme/data/odata/store";
import { Item } from "devextreme-react/box";
import "../../components/data-loader/style.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import DataLoader from "../../components/data-loader/DataLoader";
import BuildIcon from "@material-ui/icons/Build";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Selection,
  ColumnChooser,
  ColumnFixing,
} from "devextreme-react/data-grid";

import { useDataLoader } from "../../contexts/dataLoaderContext";
import { Box, Form, SelectBox } from "devextreme-react";
import { api } from "../../api/api";
import moment from "moment";
import {
  Card,
  makeStyles,
  CardContent,
  Typography,
  useTheme,
  Avatar,
  Divider,
} from "@material-ui/core";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Button } from "devextreme-react";
import TicketForm from "./ticketForm";
import { useEffect } from "react";
import TicketFormClient from "./ticketFormClient";
import role from "../../utils/role";
import { useAuth } from "../../contexts/auth";
import TicketNewMessage from "./ticketNewMessage";
import TicketNewMessageClient from "./ticketNewMessageClient";

export default function TicketResponses(props) {
  const theme = useTheme();
  const {
    loading,
    dlFilters,
    SetShouldLoadOnRender,
    newTicketMessageFormVisible,
    setNewTicketMessageFormVisible,
  } = useDataLoader();
  const [layout, setLayout] = useState("Card");
  const [answers, setAnswers] = useState([]);
  const { user } = useAuth();

  const useStyles = makeStyles({
    root: {
      maxWidth: "100%",
      minWidth: 345,
      minHeight: 60,
      margin: "4px 14% 1% 2.5%",
      float: "none",
    },
    media: {
      height: 140,
    },
    controls: {
      bott: 100,
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    chip: {
      // display: "flex",
      // justifyContent: "center",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
  });

  const checkUserType = () => {
    const roles = [role.Cliente, role.ClienteGerente];
    let remove = true;
    user.roles.forEach((x) => {
      if (remove) {
        if (roles && roles.indexOf(x.NomPermissao) === -1) {
          remove = true;
        } else {
          remove = false;
        }
      }
    });
    if (remove) return <TicketForm ticket={ticket} loading={loading} />;

    return <TicketFormClient ticket={ticket} loading={loading} />;
  };

  const checkUserTypeNewMessage = () => {
    const roles = [role.Cliente, role.ClienteGerente];
    let remove = true;
    user.roles.forEach((x) => {
      if (remove) {
        if (roles && roles.indexOf(x.NomPermissao) === -1) {
          remove = true;
        } else {
          remove = false;
        }
      }
    });
    if (remove) return <TicketNewMessage ticket={ticket} loading={loading} />;

    return <TicketNewMessageClient ticket={ticket} loading={loading} />;
  };

  const ticket = Cache["ticket"];

  useEffect(() => {
    async function loadData() {
      await api
        .get("/ticket/answers", {
          params: { ticketId: ticket.CodTicket },
        })
        .then((response) => {
          setAnswers(response.data);
        })
        .catch((error) => {});
    }
    loadData();
  }, [newTicketMessageFormVisible, ticket.CodTicket]);

  function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const classes = useStyles();

  let baseFilters = {};

  return (
    <React.Fragment>
      <Button
        style={{ float: "left" }}
        type={"back"}
        icon={"chevronleft"}
        className="content-block"
        onClick={() => {
          SetShouldLoadOnRender(true);
          props.history.goBack();
          dlFilters.setTicketId(null);
        }}
      />
      <Typography className="content-block" variant={"h2"}>
        Ticket Detalhado
      </Typography>
      <div className="form-container">
        <Box style={{ margin: "0 0 0 2%" }} width="89%" height="auto">
          <Item ratio={0} baseSize={"90%"}></Item>
          <Item ratio={0} baseSize={"22%"}>
            <div className="dx-field-label">Layout</div>
            <SelectBox
              width="fit-content"
              defaultValue="Card"
              text="Layout"
              dataSource={["Card", "Grid"]}
              //acceptCustomValue={true}
              onValueChanged={(event) => {
                setLayout(event.value);
              }}
            />
          </Item>
        </Box>
      </div>
      {checkUserType()}
      <div id="grid">
        {layout === "Grid" ? (
          <DataGrid
            className={"dx-card"}
            dataSource={answers}
            rowAlternationEnabled={true}
            //allowColumnResizing={true}
            allowColumnReordering={true}
            showBorders={false}
            disabled={loading}
            wordWrapEnabled={true}
            //focusedRowEnabled={true}
            //keyExpr={"color"}
            defaultFocusedRowIndex={0}
            columnAutoWidth={true}
            columnHidingEnabled={true}
            headerFilter={{ visible: true }}
            filterPanel={{ visible: true }}
            filterRow={{ visible: true }}
          >
            <ColumnChooser enabled={true} mode="select" />
            <ColumnFixing enabled={false} />
            <Selection mode="single" />
            <Paging defaultPageSize={20} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <FilterRow visible={true} />
            <Column
              dataField={"CodTicket"}
              dataType="string"
              caption={"Cód."}
              hidingPriority={10}
            />
            <Column
              dataField={"DatTicketResposta"}
              dataType="datetime"
              caption={"Data Mensagem"}
              //cssClass="word-wrap"
              hidingPriority={8}
            />
            <Column
              dataField={"DesTicketResposta"}
              dataType="string"
              caption={"Mensagem"}
              //cssClass="word-wrap"
              hidingPriority={4}
            />

            <Column
              dataField={"TipPublica"}
              dataType="string"
              caption={"Public"}
              cellRender={(e) => {
                return (
                  <div className="chart-cell">
                    {e.data.TipPublica ? (
                      <LockOpenOutlinedIcon />
                    ) : (
                      <LockOutlinedIcon />
                    )}
                  </div>
                );
              }}
              hidingPriority={9}
            />
          </DataGrid>
        ) : (
          <div className="form-container">
            <Typography
              className="content-block"
              style={{ marginBottom: "2%" }}
              variant={"h6"}
            >
              Mensagens do Ticket{" "}
              {
                <Button
                  icon={"add"}
                  onClick={(e) => {
                    setNewTicketMessageFormVisible("initial");
                  }}
                  style={{ marginLeft: "1%" }}
                />
              }
            </Typography>
            <Divider style={{ margin: "-1.5% 14% 2% 2.5%" }} />
            {checkUserTypeNewMessage()}
            {answers.map((item) => (
              <Card key={item.CodTicket} className={classes.root}>
                <CardContent>
                  <Box direction="col" width={"auto"} height={"auto"}>
                    <Item ratio={0} baseSize={"40%"}>
                      <CardContent>
                        <Avatar
                          style={{
                            marginBottom: "3%",
                            backgroundColor: getRandomColor(),
                          }}
                        >
                          {item.UsuarioMensagem?.charAt(0)}
                        </Avatar>

                        <Typography
                          align={"left"}
                          variant="body2"
                          color="textSecondary"
                          component="p"
                          style={{
                            marginBottom: "1.5%",
                          }}
                        >
                          {item.UsuarioMensagem} -{" "}
                          {moment(item.DatTicketResposta).fromNow()}
                          {"  "}{" "}
                          {item.IsEmplooye ? (
                            <BuildIcon style={{ width: 20, height: 20 }} />
                          ) : (
                            ""
                          )}
                        </Typography>
                        <Typography paragraph>
                          {item.DesTicketResposta}
                        </Typography>
                      </CardContent>
                    </Item>
                    <Item ratio={0} baseSize={"40%"}>
                      <Box direction="row" width={"auto"} height={"auto"}>
                        <Item ratio={0} baseSize={"96%"}></Item>
                        <Item ratio={0} baseSize={"3%"}>
                          {item.TipPublica ? (
                            <LockOpenOutlinedIcon />
                          ) : (
                            <LockOutlinedIcon style={{ color: "grey" }} />
                          )}
                        </Item>
                      </Box>
                    </Item>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </div>
        )}
      </div>
      <div className="load-indicator">
        <LoadIndicator visible={loading} height={40} width={40} />
      </div>
    </React.Fragment>
  );
}
