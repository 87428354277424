import { withNavigationWatcher } from "./contexts/navigation";
import {
  HomePageIntern,
  HomeClientManager,
  TasksPage,
  ProfilePage,
  Tickets,
  TicketResponses,
  Appointments,
  AppointmentsDetailed,
  AppointmentsByClient,
  Contacts,
  HomeClient,
  InternalTraining,
} from "./pages";
import role from "./utils/role";

const routes = [
  {
    path: "/home_intern",
    component: HomePageIntern,
    roles: [role.Suporte, role.Gerente, role.Administrador],
  },
  {
    path: "/profile",
    component: ProfilePage,
    roles: [role.Cliente, role.ClienteGerente],
  },
  {
    path: "/home_client_manager",
    component: HomeClientManager,
    roles: [role.ClienteGerente],
  },
  {
    path: "/tickets",
    component: Tickets,
    roles: [
      role.Suporte,
      role.Cliente,
      role.Gerente,
      role.ClienteGerente,
      role.Administrador,
    ],
  },
  {
    path: "/ticket_detailed",
    component: TicketResponses,
    roles: [
      role.Suporte,
      role.Cliente,
      role.Gerente,
      role.ClienteGerente,
      role.Administrador,
    ],
  },
  {
    path: "/appointments",
    component: Appointments,
    roles: [
      role.ClienteGerente,
      role.Gerente,
      role.Suporte,
      role.Administrador,
    ],
  },
  {
    path: "/appointments_by_client",
    component: AppointmentsByClient,
    roles: [
      role.ClienteGerente,
      role.Gerente,
      role.Suporte,
      role.Administrador,
    ],
  },
  {
    path: "/appointments_detailed",
    component: AppointmentsDetailed,
    roles: [role.Gerente, role.Administrador],
  },
  {
    path: "/contacts",
    component: Contacts,
    roles: [role.Suporte, role.Gerente, role.Administrador],
  },
  {
    path: "/home_client",
    component: HomeClient,
    roles: [role.Cliente],
  },
  {
    path: "/training",
    component: InternalTraining,
    roles: [role.Suporte, role.Gerente, role.Administrador, role.Operador],
  },
];

export default routes.map((route) => {
  return {
    ...route,
    component: withNavigationWatcher(route.component),
  };
});
